.UserRow {
  background-color: #ffffff;
  transition: background-color 0.3s;
  &:hover {
    background-color: #eeeeee;
    cursor: pointer;
  }
}

.missing {
  color: #aaaaaa;
}

.deleted {
  color: #ff4949;
}
