@import "var.scss";

.Search {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #d3d5d7;
    border-radius: 4px;
    display: flex;
    min-width: 546px;
    height: 42px;
    &:hover {
        border-color: $accent;
        .button {
            border-color: $accent;
        }
    }
}

.input {
    font-family: "Open Sans", sans-serif;
    flex-grow: 1;
    border-radius: 4px;
    border: none;
    padding-left: 10px;
    margin: 3px;
    font-size: 18px;
    line-height: 25px;
    outline: none;
    &::placeholder {
        color: #c4c4c4;
    }
}

.button {
    font-family: "Open Sans", sans-serif;
    display: block;
    background-color: inherit;
    border: none;
    color: $accent;
    border-left: 1px solid #d3d5d7;
    width: 90px;
    height: 100%;
    font-size: 18px;
    line-height: 25px;
    cursor: pointer;
    outline: none;
    &:hover {
        color: rgba($accent, 0.7);
    }
}

.total {
    position: absolute;
    font-size: 14px;
    line-height: 14px;
    color: #c4c4c4;
    bottom: -20px;
    left: 0;
}

.reset {
    display: inline-block;
    color: $accent;
    font-size: 14px;
    line-height: 14px;
    margin-left: 10px;
    cursor: pointer;
    padding: 4px 4px 0;
}

@include phone {
    .Search {
        min-width: 0px;
    }

    .input {
        padding-left: 5px;
        font-size: 16px;
        line-height: 25px;
    }

    .button {
        font-family: "Open Sans", sans-serif;
        display: block;
        background-color: inherit;
        border: none;
        color: $accent;
        border-left: 1px solid #d3d5d7;
        width: 90px;
        height: 100%;
        font-size: 18px;
        line-height: 25px;
        cursor: pointer;
        &:hover {
            color: rgba($accent, 0.7);
        }
    }
}
