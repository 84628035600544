@import "var.scss";

.MainHeader {
  min-height: 60px;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  align-self: flex-start;
  background-color: $accent;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.075);
}

.logo {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 12px 40px;
  font-weight: 600;
  color: #ffffff;
  text-transform: capitalize;

  img {
    margin-right: 10px;
  }
}

.nav {
  flex-grow: 1;
  display: flex;
}

.list {
  flex-grow: 1;
  display: flex;
  list-style: none;
  margin: 0;
  justify-content: flex-end;
}

.login,
.item {
  white-space: nowrap;
  display: flex;
  align-items: stretch;
  a {
    display: flex;
    align-items: center;
    padding: 10px 40px;
    height: 100%;
    text-align: center;
    color: #ffffff;

    &.active {
      background-color: #f9f9f9;
      // box-shadow: 0px 7px 0px 0px #fff;
      color: #000;
    }
  }
}

.covid_btn {
  display: flex;
  align-items: center;
  height: 38px;
  margin: 12px 15px;

  max-width: 100px;

  color: #ffffff;
  text-transform: uppercase;

  border: 1px solid #ffffff;
  border-radius: 4px;

  a {
    padding: 7px 3px;
  }
  a.active {
    color: $accent;
  }
}

.menu_btn {
  display: none;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  min-width: 58px;
}

.cross {
  display: none;
}

.menu {
  z-index: 999;
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 15px 124px;

  display: none;

  a {
    color: #fff;
    margin-bottom: 20px;
  }
}

@include phone {
  .MainHeader {
    box-shadow: none;
    justify-content: space-between;
  }

  .logo {
    max-width: 140px;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    white-space: inherit;
    padding-right: 15px;
    padding-left: 15px;
    text-transform: initial;
    height: 60px;
  }

  .nav {
    display: none;
  }

  .covid_btn__phone {
    height: 38px;
    margin: 12px 0;

    border: 1px solid #ffffff;
    border-radius: 4px;
    color: #ffffff;
    overflow: hidden;

    a {
      display: flex;
      align-items: center;
      padding: 7px 3px;

      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
      color: #ffffff;

      &.active_link {
        background-color: #ffffff;
        color: $accent;
      }
    }
  }

  .menu_btn {
    height: 60px;
    display: flex;
  }

  .active {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    width: 100vw;
    height: 100%;
    min-height: calc(100vh + 60px);
    overflow-y: hidden;

    .menu {
      display: flex;
      flex-direction: column;
    }

    .burger {
      display: none;
    }

    .cross {
      display: initial;
    }
  }

  .filler {
    flex-grow: 1;
    min-height: 20px;
  }
}
