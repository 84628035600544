@import "var.scss";

.Patients {
    flex-grow: 1;
    padding: 16px 44px 0px;
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 24px;
    line-height: 33px;
    color: $text;
}

.titleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.search_wrapper {
    margin-bottom: 25px;
}

.list {
    margin-bottom: auto;
}
.loader {
    margin: 50px auto 0;
    width: 300px;
}

@include phone {
    .Patients {
        padding: 25px 15px 30px;
    }

    .title {
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 20px;
        text-align: center;
        align-self: stretch;
    }

    .titleWrapper {
        margin-bottom: 15px;
        flex-direction: column;
        align-items: flex-end;
    }

    .list {
        overflow-x: scroll;
    }
    .loader {
        width: 260px;
    }
}
