@import "var.scss";

.Navigation {
    display: flex;
    justify-content: center;
    padding: 28px 0 28px;
}

.left,
.right {
    user-select: none;
    color: $accent;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;

    img {
        padding: 10px;
    }

    &.disabled {
        opacity: .5;
        cursor: initial;
    }
}

.pages {
    padding: 0 20px;
    white-space: nowrap;
}

.page {
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 4px;
    width: 40px;
    height: 40px;
    background-color: inherit;
    color: $accent;
    border: 1px solid $accent;
    border-radius: 50%;
    cursor: pointer;
    user-select: none;

    &.active {
        cursor: initial;
        background-color: $accent;
        color: #fff;
    }
}

.dots {
    color: $accent;
}

@include phone {
    .Navigation {
        display: flex;
        justify-content: center;
        padding: 20px 0;
    }
    
    .left,
    .right {
        font-size: 0;
        line-height: 0;
        cursor: pointer;
    
        img {
            width: 40px;
            height: 40px;
        }
    }
    
    .pages {
        padding: 0;
    }
    
    .page {
        margin: 4px;
        width: 38px;
        height: 38px;
        font-size: 15px;
    }
}
