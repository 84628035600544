@import 'var.scss';

.Footer {
    background-color: #fff;
    font-size: 14px;
    line-height: 19px;
    padding: 70px 0;
}

.container {
    max-width: 1206px;
    padding: 0 40px;
    margin: 0 auto;
}

.row {
    display: flex;
    justify-content: space-between;

    margin-bottom: 46px;
}

.column {

    a {
        display: block;
        margin-bottom: 14px;
        color: #000;
    }
}

.title {
    color: #000;
    margin-bottom: 24px;

    text-transform: uppercase;
}

@include phone {
    .row {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
    
        margin-bottom: 0;
    }

    .container {
        padding: 0 15px;
    }

    .column {
        margin-bottom: 16px;
    }

    .title {
        margin-bottom: 18px;
    }

    .copy {
        max-width: 176px;
    }
}
