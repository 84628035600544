@import 'var.scss';

.FileItem {
    height: 38px;
    width: 300px;
    position: relative;
    display: inline-flex;
    align-items: stretch;
    border: 2px solid #f5f5f5;
    border-radius: 4px;
    transition: border-color .3s;
    margin: 0 20px 20px 0;

    &:hover:not(.download_file) {
        cursor: pointer;
        border-color: $error;
        .remove {
            background-color: $error;
        }
        .filename {
            border-color: $error;
        }
    }
    &.download_file:hover {
        cursor: pointer;
        border-color: $accent;
        .download {
            background-color: $accent;
        }
        .filename {
            border-color: $accent;
        }
    }
}


.filename {
    padding: 3px 33px;
    border-left: 2px solid #f5f5f5;
    transition: border-color .3s;
}

.remove,.download {
    width: 58px;
    height: 34px;
    mask-repeat: no-repeat;
    mask-position: center center;
    transition: background-color 0.3s;
}

.remove { 
    background-color: #f5f5f5;
    mask-size: 16px 16px;
}

.download {
    background-color: #000;
    mask-size: 20px 20px;
}

.link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

@include phone {
    .FileItem {
        width: 195px;
        margin: 0;
    }
    
    
    .filename {
        font-size: 16px;
        padding: 6px 15px;
    }
    
    .remove,.download {
        width: 34px;
        height: 34px;
    }
}