.UsersLogRow {
  background-color: #ffffff;
}

.hoveredRow {
  transition: background-color 0.3s;
  &:hover {
    background-color: #eeeeee;
    cursor: pointer;
  }
}

.missing {
  color: #aaaaaa;
}
