@import "var.scss";

.ServicesLog {
}
.header {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  margin: -5px -5px 15px;
}

.select_performer,
.select_creator {
  flex: 1 0 400px;
  display: flex;
  align-items: center;

  margin: 5px 5px;

  span {
    margin-right: 10px;
  }

  & > .select_wrapper {
    margin-right: 0;
  }
}
.select_wrapper {
  flex: 1 0 400px;
  margin: 5px 5px;
}

.datepicker_wrapper {
  margin: 5px 5px;
}

.select_text {
  margin-right: 20px;
  vertical-align: middle;
  margin-bottom: 5px;
}

.button_wrapper {
  width: 100%;
}

.loader {
  width: 300px;
  margin: 50px auto 0;
}

.status_loader {
  align-self: center;
  width: 100%;
  height: 28px;
  margin-top: 15px;
}

@include phone {
  .ServicesLog {
    padding: 25px 0px;
  }
  .header {
    flex-direction: column;
    margin-bottom: 30px;
  }
  .status_loader {
    height: 18px;
    margin-left: 0px;
    margin-top: 0;
    width: 100%;

    order: 3;
  }

  .select_performer,
  .select_creator {
    flex: 0 0 auto;
    flex-direction: column;
    align-items: flex-start;
    max-width: 500px;
    width: 100%;
    margin-bottom: 15px;

    .select_wrapper {
      width: 100%;
      max-width: 500px;
      margin: 0;
      // flex: 0 0 auto;
    }
  }

  .select_wrapper {
    display: inline-block;
    width: 100%;
    max-width: 500px;
    flex: 0 0 auto;
  }

  .datepicker_wrapper {
    order: 2;
  }

  .list {
    overflow-x: scroll;
  }

  .loader {
    width: 260px;
  }

  .select_text {
    font-size: 14px;
    margin-right: 40px;
  }
}
