@import 'var.scss';

.Back {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: 1px solid $accent;
    border-radius: 4px;
}
