@import "var.scss";

.Supports {
  flex-grow: 1;
  padding: 20px 40px 0;
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 24px;
  line-height: 33px;
  color: $text;
}
.titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
.list_wrapper {
  margin-bottom: auto;
}

.loader {
  margin: 30px auto 0;
  width: 300px;
}

.search_wrapper {
  margin-bottom: 25px;
}

@include phone {
  .Supports {
    padding: 25px 15px;
  }
  .title {
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    align-self: stretch;
    margin-bottom: 20px;
  }
  .titleWrapper {
    margin-bottom: 15px;
    flex-direction: column;
    align-items: flex-end;
  }
  .list_wrapper {
    overflow-x: scroll;
  }
  .loader {
    width: 260px;
  }
}
