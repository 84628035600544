@import "var.scss";

.Consultation {
    flex-grow: 1;
    padding: 17px 48px 48px 40px;
}

.back {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    & > span {
        margin-left: 14px;
    }
}

.links {
    font-size: 24px;
    line-height: 33px;
    display: flex;
    margin-bottom: 24px;
}

.link,
.active_link {
    margin-right: 45px;
    cursor: pointer;
    opacity: 1;
}

.link {
    opacity: 0.5;
}

@include phone {
    .Consultation {
        padding: 25px 0 30px 0;
    }

    .back {
        margin-bottom: 20px;
        margin-left: 15px;
    }

    .links {
        flex-direction: column;
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 20px;
        padding: 0 15px;
    }
}
