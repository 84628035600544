@import "var.scss";

.ArticleExpanded {
    max-width: 1126px;
    margin: 52px auto 48px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
}

.header {
    padding: 28px 116px 14px;
    border-bottom: 1px solid #eeeeee;
}

.title {
    position: relative;
    font-size: 20px;
    a {
        position: absolute;
        bottom: 0;
        left: -72px;
    }
}

.date {
    color: #c4c4c4;
}

.content {
    padding: 14px 116px 48px;
}

.image {
    height: 475px;
    border-radius: 4px;
    margin-bottom: 44px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.text {
    white-space: pre-line;
    line-height: 28px;
}

@include phone {
    .ArticleExpanded {
        max-width: 600px;
        margin: 25px auto;
        padding: 0 15px;
        border: none;
    }

    .header {
        padding: 42px 0 0 0;
        border-bottom: none;
    }

    .title {
        line-height: 22px;
        position: relative;
        a {
            position: absolute;
            bottom: auto;
            left: 0;
            top: -42px;
        }
    }

    .date {
        color: #c4c4c4;
    }

    .content {
        padding: 5px 0 20px;
    }

    .image {
        height: 160px;
        margin-bottom: 16px;
    }
}
