@import "var.scss";

.NewDocument {
  flex-grow: 1;
  padding: 17px 48px 48px 40px;
}

.back {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  & > span {
    margin-left: 14px;
  }
}

.title {
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 28px;
  font-weight: normal;
}
.input_title {
  margin-bottom: 10px;
}
.form {
  border: 1px solid #eeeeee;
  border-radius: 4px;
}

.section_title {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  padding: 18px 0 18px 70px;
  border-bottom: 1px solid #eeeeee;
}
.file_tip{
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10px;
}
.attachments {
  margin-bottom: 20px;
}
.section_content {
  padding: 28px 70px;
}

.select_box {
  display: flex;
  margin-bottom: 40px;
  select {
    max-width: 456px;
    flex-grow: 1;
  }
  .date{
    margin-left: 20px;
  }
}

.extra_section {
  padding: 28px 70px 48px;
  button {
    width: 230px;
  }
}

.download {
  margin-bottom: 40px;
}

.text {
  margin-bottom: 28px;
  margin-right: 20px;
}

.download_description {
  font-size: 14px;
  line-height: 19px;
  max-width: 370px;
  margin-bottom: 27px;
}

.attention {
  max-width: 470px;
  margin-bottom: 20px;
}

@include phone {
  .NewDocument {
    flex-grow: 1;
    padding: 25px 0px 30px;
  }
  
  .back {
    margin-bottom: 18px;
    margin-left: 27px;
    & > span {
      margin-left: 10px;
    }
  }
  
  .title {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 20px;
    text-align: center;
  }
  .input_title {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 19px;
  }
  
  .section_title {
    padding: 15px 0 15px 27px;
  }
  .file_tip{
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 10px;
  }
  .section_content {
    padding: 20px 27px 30px;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .select_box {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    select {
      max-width: 300px;
      margin-bottom: 20px;
      flex-grow: 1;
    }
    .date{
      margin-left: 0;
      max-width: 300px;

    }
  }
  
  .extra_section {
    padding: 20px 27px 30px;
    max-width: 400px;
    margin: 0 auto;
    button {
      width: 100%;
      margin: 15px auto 0;
    }
  }
}
