@import "var.scss";

.DoctorInfo {
    padding: 17px 48px 40px 40px;
}

.link_wrapper {
    margin-bottom: 23px;
}

.link_text {
    margin-left: 14px;
}

@include phone {
    .DoctorInfo {
        padding: 25px 0 30px 0;
    }
    
    .link_wrapper {
        margin-bottom: 20px;
        margin-left: 15px;
    }
    
    .link_text {
        margin-left: 14px;
    }
}