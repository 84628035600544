@import "var.scss";

.Doctors {
  flex-grow: 1;
  padding: 20px 40px 0px;
  display: flex;
  flex-direction: column;
}
.titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
.search_wrapper {
  margin-bottom: 35px;
}
.title {
  font-size: 24px;
  line-height: 33px;
  color: $text;
}
.spec {
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
}
.spec_title {
  margin-right: 16px;
}
.spec_select {
  width: 410px;
}

.table_wraper {
  margin-bottom: auto;
}

.loader {
  margin: 50px auto 0;
  width: 300px;
}

@include phone {
  .Doctors {
    padding: 25px 15px;

    select {
      margin-bottom: 10px;
    }
  }
  .titleWrapper {
    margin-bottom: 15px;
    flex-direction: column;
    align-items: flex-end;
  }
  .title {
    align-self: center;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 15px;
  }
  .spec {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0зч;
  }
  .spec_title {
    margin-right: 0;
    margin-bottom: 5px;
    font-size: 16px;
  }
  .spec_select {
    width: auto;
  }

  .table_wraper {
    overflow-x: scroll;
  }
  .loader {
    width: 260px;
}
}
