@import "var.scss";

.ResultItem {
    display: flex;
    flex-direction: column;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    margin-bottom: 20px;
}

.header {
    display: flex;
    border-bottom: 1px solid #eeeeee;
}

.about {
    flex-grow: 1;
    padding: 14px 26px 14px 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.type {
    font-weight: bold;
    margin-right: 32px;
}

.status_wrapper {
    flex-grow: 1;
}

.date {
    text-align: right;
    color: #c4c4c4;
}

.repeat {
    border-left: 1px solid #eeeeee;
    a {
        display: block;
        color: #c4c4c4;
        padding: 14px 46px;

        &:hover {
            color: $accent;
        }
    }
}

.main {
    padding: 10px 16px 20px;
}

.description {
    margin-bottom: 20px;
}

.row {
    display: flex;
    height: 38px;
    align-items: stretch;
}

.btn {
    width: 304px;
    margin-right: 29px;
}

.file {
    margin-bottom: -20px;
}

@include phone {
    .ResultItem {
        font-size: 16px;
        margin-bottom: 15px;
    }

    .about {
        padding: 6px 10px 10px 10px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
    }

    .status_wrapper {
        margin-bottom: 20px;
    }

    .score {
        font-size: 16px;
        line-height: 16px;
    }

    .status_text {
        display: none;
    }

    .date {
        text-align: left;
        font-size: 13px;
        line-height: 13px;
    }

    .repeat {
        display: flex;
        align-items: center;

        a {
            padding: 6px 20px;
        }
    }

    .main {
        padding: 10px 10px 15px;
    }

    .description {
        line-height: 24px;
        margin-bottom: 20px;
    }

    .row {
        display: flex;
        flex-direction: column;
        height: auto;
        align-items: stretch;
    }

    .btn {
        width: 100%;
        max-width: 400px;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .file {
        margin-bottom: -5px;
        max-width: 400px;
    }
}
