@import "var.scss";

.DoctorProfile {
    border: 1px solid #eeeeee;
    border-radius: 4px;
}

.header {
    display: flex;
    padding: 40px 40px 40px 78px;
}
.main_info {
    margin-right: auto;
    display: flex;
    flex-direction: column;
}

.specialization {
    font-size: 14px;
    line-height: 19px;
    color: #c4c4c4;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.name {
    margin-bottom: 5px;
    font-size: 24px;
    line-height: 33px;
    max-width: 360px;
    font-weight: bold;
}
.category {
    margin-bottom: auto;
}
.full {
    .category {
        margin-bottom: 5px;
    }
    .experience {
        margin-bottom: auto;
    }
}

.header_btn {
    margin-left: 78px;
    margin-bottom: 20px;
    max-width: 350px;
    display: grid;
    gap: 10px;
}

.footer_btn {
    display: grid;
    gap: 10px;
}

.photo {
    min-width: 198px;
    height: 198px;
    margin-left: 30px;
    border-radius: 4px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #eeeeee;
}

.education,
.qualification,
.skills {
    padding: 30px 40px 18px 78px;
    border-top: 1px solid #eeeeee;

    &__title {
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 19px;
        color: #c4c4c4;
        text-transform: uppercase;
    }
}

.container {
    max-width: 583px;
}

.education {
    &__item {
        margin-bottom: 30px;
    }
    &__type {
        font-weight: 600;
        margin-bottom: 10px;
    }
}

.qualification {
    &__item {
        display: flex;
        margin-bottom: 30px;
    }
    &__year {
        min-width: 117px;
        font-weight: 600;
    }
}

.skills__item {
    margin-bottom: 30px;
    white-space: pre-wrap;
}

.footer_btn {
    border-top: 1px solid #eeeeee;
    padding: 28px 107px 28px 167px;
}

@include phone {
    .DoctorProfile {
        font-size: 16px;
        line-height: 28px;
    }
    .header {
        padding: 10px 25px;
        font-size: 11px;
        line-height: 15px;
    }
    .main_info {
        flex-grow: 1;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
    }

    .full {
        .experience {
            margin-bottom: 10px;
        }
    }

    .specialization {
        font-size: inherit;
        line-height: inherit;
        margin-bottom: 3px;
    }

    .name {
        margin-bottom: 3px;
        font-size: 14px;
        line-height: 18px;
        max-width: 180px;
    }
    .category {
        margin-bottom: 3px;
    }

    .header_btn {
        margin-left: 0;
        margin-bottom: 0;
        padding: 0px 20px 20px;
        max-width: 100%;
    }

    .photo {
        min-width: 80px;
        height: 80px;
        margin-left: 10px;
    }

    .education,
    .qualification,
    .skills {
        padding: 15px 25px 0;

        &__title {
            margin-bottom: 20px;
            color: #c4c4c4;
            text-transform: uppercase;
            font-size: 11px;
            line-height: 15px;
        }
    }

    .container {
        max-width: auto;
    }

    .education {
        &__item {
            margin-bottom: 19px;
        }
        &__type {
            font-weight: 600;
            margin-bottom: 10px;
        }
    }

    .qualification {
        &__item {
            display: flex;
            margin-bottom: 19px;
        }
        &__year {
            min-width: 100px;
            font-weight: 600;
        }
    }

    .skills__item {
        margin-bottom: 30px;
        white-space: pre-wrap;
    }

    .footer_btn {
        border-top: 1px solid #eeeeee;
        padding: 15px 25px;
    }
}
