$text: #181818;
$accent: #18D0C3;
$btn-hover: #12a59a;
$invalid: #F96770;
$hr: #E0E5E8;
$sidebar: #3F3F3F;
$bg: #ffffff;
$error:  #EB5757;
$scrollbar: #3F3F3F;

// --------- МИКСИНЫ ---------

@mixin _1170 {
    @media (max-width: 1170px){
        @content;
    }
}

@mixin _979 {
    @media (max-width: 979px){
        @content;
    }
}

@mixin phone {
    @media (max-width: 768px) and (orientation: portrait),(max-width: 979px) and (orientation: landscape)  {
        @content;
    }
}

@mixin _600 {
    @media (max-width: 600px){
        @content;
    }
}

@mixin _480 {
    @media (max-width: 480px){
        @content;
    }
}

@mixin _350{
    @media (max-width: 350px){
        @content;
    }
}