@import "var.scss";

.datepicker {
  width: 100%;
  min-width: 235px;
  margin-left: 0;

  border: 1px solid #d3d5d7;
  border-radius: 4px;
  padding: 11px 23px;
  padding-right: 50px !important;
  font-size: 18px;
  line-height: 25px;
  outline: none;
  color: $text;
  cursor: pointer;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url("/profile/images/select-btn.svg") calc(100% - 13px) 50%
      no-repeat,
    url("/profile/images/line.svg") calc(100% - 40px) 50% repeat-y;
  &:hover {
    border-color: $accent;
  }
}

.calendar {
  padding: 14px 28px;
  width: 280px;

  .react-datepicker {
    &__triangle {
      display: none;
    }

    &__navigation {
      top: 20px;
      width: 28px;
      height: 28px;
      border: none !important;
      background-repeat: no-repeat;
      background-position: center center;
      outline: none;

      &--previous {
        left: 60px;
        background-image: url("/profile/images/calendar-previous.svg");
      }

      &--next {
        right: 60px;
        background-image: url("/profile/images/calendar-next.svg");
      }

      &:hover {
        opacity: 0.7;
      }
    }

    &__month-container {
      text-transform: capitalize;
      width: 100%;
    }

    &__current-month {
      top: 18px;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }

    &__header {
      background-color: transparent;
      border: none;

      &__dropdown {
        padding: 5px 0;

        select {
          height: 20px;
        }
      }
    }

    &__day-names {
      height: auto;
      display: flex;
      justify-content: center;
    }

    &__day-name {
      margin: 0 3px;
      font-weight: bold;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      color: #d3d5d7;
    }

    &__month {
      margin: 0;
      margin-top: 16px;
    }

    &__week {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }

    &__day {
      margin: 0;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #7a7585;
      outline: none !important;

      &--selected {
        background-color: #2cf5e7;
        border: none;
        &:hover {
          background-color: #2cf5e7;
        }
      }

      &--in-range {
        background-color: #2cf5e7;
        border: none;
        border-left-color: #2cf5e7;
        border-right-color: #2cf5e7;
        border-radius: 0;
        &:hover {
          border-radius: 0;
          background-color: inherit;
          box-shadow: inset 0 0 0 2px #2cf5e7;
        }
      }

      &--range-start {
        background-color: #2cf5e7;
        border: none;
        border-right-color: #2cf5e7;
        border-top-left-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
        &:hover {
          border-top-left-radius: 50%;
          border-bottom-left-radius: 50%;
          background-color: inherit;
          box-shadow: inset 0 0 0 2px #2cf5e7;
        }
      }

      &--range-end {
        background-color: #2cf5e7;
        border: none;
        border-left-color: #2cf5e7;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        &:hover {
          border-top-right-radius: 50%;
          border-bottom-right-radius: 50%;
          background-color: inherit;
          box-shadow: inset 0 0 0 2px #2cf5e7;
        }
      }

      &--in-selecting-range {
        border: none;
        background-color: #2cf5e7 !important;
        border-radius: 0;
        color: #ffffff;
        &:hover {
          border-radius: 0;
        }
      }

      &--outside-month {
        color: #3c3844;
      }
      &--disabled {
        opacity: 0.5;
      }
    }
  }
}

.custom-datepicker {
  &__btn-wrapper {
    text-align: right;
  }
  &__btn {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: $accent;
    padding: 3px 5px;
    margin: 0 3px;
    cursor: pointer;
  }
}

@include phone {
  .datepicker {
    padding: 10px;
    padding-right: 50px !important;
    font-size: 15px;
    width: 200px;
    min-width: 150px;
    margin-left: 0;
  }

  .react-datepicker__navigation {
    &--previous {
      left: 20px !important;
    }

    &--next {
      right: 20px !important;
    }
  }
}
