@import "var.scss";

.FileLoader {
    position: relative;
    height: 38px;
    max-width: 300px;
    min-width: 100px;
    border: 2px solid #f5f5f5;
    border-radius: 4px;
    box-shadow: inset 0 0 0 2px #ffffff;
    margin-bottom: 20px;
    overflow: hidden;

    span {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: $accent;
        animation: loading 1.5s infinite;
    }
}

@keyframes loading {
    0% {
        width:0;
    }
    100% {
        width: 100%;
    }
}

@include phone {
    .FileLoader {
        max-width: 195px;
        height: 35px;
        margin-bottom: 5px;
    }
}
