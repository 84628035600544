@import "var.scss";

.QuestionItem {
    margin-bottom: 48px;
}

.question {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 12px;
    padding-left: 116px;
    margin-bottom: 28px;
}

.question_input {
    max-width: 660px;
    margin-bottom: 28px;
    padding-left: 116px;
}

.answer_list {
    display: block;
    padding-left: 116px;
    margin: 0;
    list-style: none;

    &.horizontal {
        display: flex;

        .answer_item {
            & + .answer_item {
                margin-top: 0px;
                margin-left: 28px;
            }
        }

        .answer_label {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .radio_btn {
            margin-right: 0;
        }

        .answer_number {
            margin-right: 0;
            margin-top: 6px;
        }
    }
}

.explanation {
    font-size: 14px;
    line-height: 23px;
    margin-top: 12px;
}

.answer_item {
    & + .answer_item {
        margin-top: 20px;
        margin-left: 0px;
    }
}

.answer_label {
    position: relative;
    display: flex;
    align-items: center;
}

.answer_input {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    &:checked ~ .radio_btn {
        border: 1px solid $accent;
        background-color: $accent;
        box-shadow: inset 0 0 0 5px #fff;
    }

    &:checked ~ .checkbox_btn {
        background-color: $accent;

        &::after {
            display: block;
            left: 10px;
            top: 6px;
            width: 9px;
            height: 16px;
            border: solid #fff;
            border-width: 0 5px 5px 0;
            transform: rotate(45deg);
        }
    }
}

.answer_number {
    display: inline-block;
    margin-right: 22px;
}

.answer {
    font-size: 14px;
    line-height: 24px;
}

.radio_btn {
    display: inline-block;
    width: 30px;
    min-width: 30px;
    height: 30px;
    border: 1px solid #d3d5d7;
    border-radius: 50%;
    margin-right: 14px;
    cursor: pointer;
}

.checkbox_btn {
    position: relative;
    display: inline-block;
    vertical-align: bottom;
    width: 30px;
    min-width: 30px;
    height: 30px;
    border: 1px solid #d3d5d7;
    border-radius: 4px;
    margin-right: 14px;

    &:hover {
        background-color: #f0f1f1;
        cursor: pointer;
    }
    &::after {
        content: "";
        position: absolute;
        display: none;
    }
}

@include phone {
    .QuestionItem {
        margin-bottom: 20px;
        margin-right: 15px;
    }
    
    .question {
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 5px;
        padding-left: 27px;
        margin-bottom: 15px;
    }
    
    .question_input {
        max-width: none;
        width: 100%;
        margin-bottom: 15px;
        padding-left: 15px;
    }
    
    .answer_list {
        padding-left: 15px;
        font-size: 13px;
        line-height: 15px;
    
        &.horizontal {
            height: 60px;
            overflow-x: scroll;
            justify-content: stretch;
            .answer_item {
                & + .answer_item {
                    margin-left: 15px;
                }
            }
            .answer_number {
                margin-right: 0;
                margin-top: 6px;
            }
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
    
    .explanation {
        font-size: 12px;
        line-height: 20px;
        margin-top: 10px;
    }
    
    .answer_item {
        & + .answer_item {
            margin-top: 15px;
            margin-left: 0px;
        }
    }
    
    .answer_number {
        font-weight: bold;
        margin-right: 15px;
    }
    
    .answer {
        font-size: 13px;
        line-height: 20px;
    }
    
    .radio_btn {
        margin-right: 10px;
    }
    
    .checkbox_btn {
        margin-right: 10px;
    } 
}
