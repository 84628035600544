@import "var.scss";

.select_wrapper {
  position: relative;
  &:hover {
    .Select {
      border-color: $accent;
    }

    .label_top {
      color: $accent;
    }
  }
}

.Select {
  width: 100%;
  color: $text;
  border: 1px solid #d3d5d7;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 11px 23px;
  padding-right: 50px;
  font-size: 18px;
  line-height: 25px;
  outline: none;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url("/profile/images/select-btn.svg") calc(100% - 13px) 50%
      no-repeat,
    url("/profile/images/line.svg") calc(100% - 40px) 50% repeat-y;

  option {
    color: #000;
  }

  &:disabled {
    opacity: 0.4;
    background-color: #fff;
    &:hover {
      border: 1px solid #d3d5d7;
    }
  }

  &::-ms-expand {
    display: none;
  }
}

.label_top {
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
  top: -7px;
  left: 15px;
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  padding: 0 5px;
  color: #c4c4c4;
  white-space: nowrap;
}

@include phone {
  .Select {
    padding: 11px 50px 11px 10px;
    font-size: 14px;
    line-height: 23px;
  }

  .label_top {
    top: -6px;
    left: 10px;
    font-size: 12px;
    line-height: 12px;
    height: 12px;
  }
}
