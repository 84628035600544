@import "var.scss";

.Header {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
  font-size: 18px;
  line-height: 20px;

  background: $accent;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 44px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
}

.logo {
  display: flex;
  align-items: center;

  margin-right: auto;
  padding: 12px 40px;

  font-weight: 600;
  text-transform: capitalize;
  color: #ffffff;
  white-space: nowrap;

  img {
    margin-right: 10px;
  }
}

.navlink {
  display: flex;
  padding: 20px 18px;
  margin-right: 4px;
  color: #fff;
  transition: all 0.3s;

  &.active,
  &:hover {
    background-color: $bg;
    color: #000;
    outline: none;
    // box-shadow: 0px 6px 1px $bg;

    .icon {
      background-color: #000;
    }
  }
}
.role {
  color: #ffffff;
  margin-right: 10px;
  margin-left: 10px;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-color: #fff;

  mask-repeat: no-repeat;
  mask-size: contain;
  transition: background-color 0.3s;
}

.logout {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 60px;
}

.menu_btn {
  display: none;
  align-items: center;
  justify-content: center;
  padding: 15px;
  cursor: pointer;
}

.cross {
  display: none;
}

@include phone {
  .Header {
    box-shadow: none;
    padding-right: 0;
  }

  .logo {
    text-transform: initial;
    max-width: 100px;
    font-size: 14px;
    line-height: 14px;
  }

  .navlink {
    font-size: 0;
    display: flex;
    padding: 20px 20px;
    margin-right: 0;

    &.active,
    &:hover {
      background-color: $accent;
      color: inherit;
      outline: none;
      box-shadow: none;

      .icon {
        background-color: #fff;
      }
    }
  }

  .logout {
    display: none;
  }
  .role {
    display: none;
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 0px;
  }

  .menu_btn {
    width: 60px;
    height: 60px;
    display: flex;
  }

  .active {
    .burger {
      display: none;
    }

    .cross {
      display: initial;
    }
  }
}
