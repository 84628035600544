@import "var.scss";

.Invites {
  flex-grow: 1;
  padding: 16px 44px 0px;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 24px;
  line-height: 33px;
  color: $text;
}

.header {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.inviteWrapper {
  display: flex;

  margin-bottom: 40px;
}

.btn_submit {
  flex: 0 1 252px;
  margin-left: 10px;
}

.btn {
  height: 48px;
}

.search_wrapper {
  margin-bottom: 25px;
}

.list {
  margin-bottom: auto;
}
.loader {
  margin: 50px auto 0;
  width: 300px;
}

@include phone {
  .Invites {
    padding: 25px 15px 30px;
  }

  .title {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 20px;
    text-align: center;
    align-self: stretch;
  }

  .titleWrapper {
    margin-bottom: 15px;
    flex-direction: column;
    align-items: flex-end;
  }

  .inviteWrapper {
    flex-direction: column;
  }

  .btn_submit {
    flex-basis: 42px;
    margin: 20px 0 0;
  }

  .header {
    flex-direction: column;
    margin-bottom: 30px;
  }

  .list {
    overflow-x: scroll;
  }
  .loader {
    width: 260px;
  }
}
