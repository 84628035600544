@import "var.scss";

.FaqItem {
    padding: 40px 76px 20px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
}
.question {
    margin-bottom: 20px;
}

.answer {
    line-height: 28px;
    max-height: 109px;
    overflow: hidden;
    &.full {
        max-height: 100%;
        overflow: inherit;
    }
}

.more {
    cursor: pointer;
    align-self: flex-end;
    color: $accent;
    font-size: 14px;
    padding: 5px;
}

@include phone {
    .FaqItem {
        padding: 15px 20px 10px;
    }

    .question {
        margin-bottom: 15px;
        font-size: 18px;
        line-height: 22px;
    }

    .answer {
        line-height: 22px;
    }
}
