@import "var.scss";

.OurSpecialists {
    min-height: 697px;
    max-width: 1226px;
    margin: 0 auto;
    padding: 60px 0 49px;
}

.title {
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    margin-bottom: 7px;
}

.subtitle {
    text-align: center;
    margin-bottom: 48px;
}

.specializations {
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 36px;
}

.content {
    display: flex;
}

.list {
    position: sticky;
    top: 75px;
    min-width: 400px;
    max-width: 500px;
    padding-right: 10px;
    margin-right: 22px;
    max-height: 610px;
    overflow-y: auto;
    & > * {
        margin-bottom: 20px;
    }
}
.doctors_page {
    min-width: 550px;
}
.more {
    color: $accent;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    cursor: pointer;
    padding-right: 20px;
}

.start_btn {
    width: 240px;
}

@include phone {
    .OurSpecialists {
        min-height: 400px;
        padding: 25px 0;
        max-width: 600px;
        margin: 0 auto;
    }
    .title {
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 15px;
    }
    .subtitle {
        display: none;
    }
    .specializations {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 19px;

        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .content {
        flex-direction: column;
    }
    .list {
        position: inherit;
        display: flex;
        padding-right: 0px;
        margin-right: 0px;
        min-width: 0;
        overflow-x: scroll;
        overflow-y: initial;
        padding: 0 15px;
        &::-webkit-scrollbar {
            display: none;
        }
        & > * {
            margin-right: 15px;
        }
    }
    .doctors_page {
        min-width: auto;
    }
    .more {
        font-size: 16px;
    }
}
