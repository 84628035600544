@import "var.scss";

.PatientEdit {
  flex-grow: 1;
  padding: 20px 40px;
}
.title {
  font-size: 24px;
  line-height: 33px;
  color: $text;
  margin-bottom: 22px;
}
.back {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  & > span {
    margin-left: 14px;
  }
}
.form {
  border: 1px solid #eeeeee;
  border-radius: 4px;
}
.photo {
  width: 200px;
  height: 200px;
  border-radius: 4px;
}
.picture_loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 200px;
  height: 200px;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  border: 1px dashed #d3d5d7;
  border-radius: 4px;
}

.section_title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: normal;
  font-size: 19px;
  line-height: 25px;
  padding: 18px 0 18px 70px;
  border-bottom: 1px solid #eeeeee;
  h5 {
    flex: 1 1 auto;
    font-weight: normal;
    font-size: 19px;
    line-height: 25px;
    display: inline-block;
  }
}
.block_btn {
  float: right;
  margin-right: 22px;
  font-size: 18px;
  line-height: 25px;
  color: #ff4949;
  cursor: pointer;
}
.section_content {
  padding: 28px 70px;
  width: 100%;
}
.section {
  padding: 28px 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.section_withPhoto {
  padding: 28px 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  input {
    color: #646464;
  }
}
.inputs {
  width: 100%;
  max-width: 426px;
  input {
    margin-bottom: 20px;
  }
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dataFetchStatus {
  font-size: 12px;
  line-height: 16px;
  .error_status {
    color: $invalid;
  }
  .success_status {
    color: $btn-hover;
  }
}

.container {
  max-width: 344px;
}

.subtitle {
  font-size: 14px;
  line-height: 28px;
  max-width: 435px;
  margin-bottom: 25px;
}

.button_wrapper {
  max-width: 426px;
}

.doc_list {
  margin: -20px;
  display: flex;
  flex-wrap: wrap;
  & > * {
    //для всех дочерних
    margin: 20px;
  }
}

.message {
  font-size: 14px;
  line-height: 16px;
  color: $accent;
  margin-top: 6px;
}

.results_list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 20px;
}

@include phone {
  .PatientEdit {
    padding: 25px 0;
  }
  .title {
    font-size: 20px;
    line-height: 27px;
    margin: 0 27px 20px;
    text-align: center;
  }
  .back {
    margin-bottom: 10px;
    margin-left: 27px;
    & > span {
      margin-left: 10px;
    }
  }
  .section_title {
    flex-direction: column-reverse;
    font-size: 16px;
    line-height: 22px;
    padding: 15px 27px 15px 27px;
    h5 {
      font-size: 16px;
      line-height: 22px;
    }
  }
  .block_btn {
    font-size: 16px;
    line-height: 22px;
    padding-right: 0;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .section_withPhoto {
    padding: 20px 27px;
    max-width: 450px;
    margin: 0 auto;
    flex-direction: column-reverse;
    justify-content: flex-end;
    input {
      color: #646464;
    }
  }
  .photo {
    width: 150px;
    height: 150px;
    margin: 0 auto 20px;
  }
  .section_content {
    padding: 20px 27px;
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
  }
  .section {
    padding: 20px 27px;
    max-width: 450px;
    margin: 0 auto;
  }
  .inputs {
    max-width: none;
  }
  .subtitle {
    font-size: 14px;
    line-height: 22px;
    max-width: none;
    margin-bottom: 20px;
  }

  .button_wrapper {
    max-width: none;
  }
  .empty_list {
    font-size: 16px;
    line-height: 24px;
  }

  .results_list {
    display: block;
  }
}
