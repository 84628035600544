@import "var.scss";

.info {
    padding-left: 116px;
    margin-bottom: 28px;
}

@include phone {
    .info {
        padding-left: 27px;
        margin-bottom: 15px;
        font-size: 13px;
        line-height: 19px;
    }
}