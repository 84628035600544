@import "var.scss";

.ConsultationsLog {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 24px;
  line-height: 33px;
  color: $text;
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -5px -5px 15px;
}
.select_performer,
.select_creator {
  flex: 1 0 400px;
  display: flex;
  align-items: center;

  margin: 5px 5px;

  span {
    margin-right: 5px;
  }

  & > .select_wrapper {
    margin-right: 0;
  }
}

.select_wrapper {
  flex: 1 0 300px;
  margin: 5px 5px;
}

.datepicker_wrapper {
  margin: 5px;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.btn_submit {
  flex: 0 1 252px;
  margin-left: 10px;
}

.btn {
  height: 48px;
}

.list {
  overflow-x: scroll;
}

.loader {
  width: 300px;
  margin: 50px auto 0;
}

.status_loader {
  align-self: center;
  width: 100%;
  height: 28px;
  margin-top: 15px;
}

@media (min-width: 980px) and(max-width: 1439px) {
  .list {
    max-width: calc(100vw - 280px - 80px);
    width: 100%;
    margin: 0 auto;
    &.sidebar_collapsed {
      max-width: calc(100vw - 108px - 80px);
    }
  }
}

@include phone {
  .ConsultationsLog {
    padding: 25px 5px;
  }

  .title {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 20px;
    text-align: center;
    align-self: stretch;
  }

  .titleWrapper {
    margin-bottom: 15px;
    flex-direction: column;
    align-items: flex-end;
  }

  .btn_submit {
    flex-basis: 42px;
    margin: 20px 0 0;
  }

  .header {
    flex-direction: column;
    margin-bottom: 30px;
    align-items: flex-start;
  }

  .status_loader {
    height: 18px;
    margin-left: 0px;
    margin-top: 0;
    width: 100%;

    order: 3;
  }

  .select_performer,
  .select_creator {
    flex: 0 0 auto;
    flex-direction: column;
    align-items: flex-start;
    max-width: 500px;
    width: 100%;
    margin-bottom: 15px;

    .select_wrapper {
      width: 100%;
      max-width: 500px;
      margin: 0;
    }
  }

  .select_wrapper {
    display: inline-block;
    width: 100%;
    max-width: 500px;
    flex: 0 0 auto;
  }

  .datepicker_wrapper {
    order: 2;
  }

  .list {
    overflow-x: scroll;
  }
  .loader {
    width: 260px;
  }
}
