@import "var.scss";

.CreateRole {
    width: 426px;
    padding: 48px 0;
    input {
        margin-bottom: 28px;
    }
}

.title {
    margin-bottom: 15px;
    text-align: center;
}

.subtitle {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 30px;
    text-align: center;
}

.link {
    position: relative;
}

.back {
    position: absolute;
    top: 0;
    left: -40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: 1px solid $accent;
    border-radius: 4px;
    cursor: pointer;
}

.row {
    display: flex;
}

.date {
    flex-grow: 1;
}

.picture_loader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 200px;
    height: 200px;
    margin: 0 auto;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    border: 1px dashed #d3d5d7;
    border-radius: 4px;

    margin-bottom: 48px;
}

.picture_input {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
}

.remove_btn {
    position: absolute;
    z-index: 10;
    top: 10px;
    right: 10px;
    width: 32px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #3f3f3f;
    border-radius: 4px;
    cursor: pointer;
}

.error {
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;

    color: $invalid;
}

.checkbox {
    display: block;
    margin-bottom: 20px;

    input {
        display: none;

        &:checked ~ .checkmark {
            background-color: $accent;

            &::after {
                display: block;
                left: 10px;
                top: 6px;
                width: 9px;
                height: 16px;
                border: solid #fff;
                border-width: 0 5px 5px 0;
                transform: rotate(45deg);
            }
        }
    }
}

.checkmark {
    position: relative;
    display: inline-block;
    vertical-align: bottom;
    width: 30px;
    height: 30px;
    border: 1px solid #d3d5d7;
    border-radius: 4px;
    margin-right: 14px;

    &:hover {
        background-color: #f0f1f1;
        cursor: pointer;
    }
    &::after {
        content: "";
        position: absolute;
        display: none;
    }
}

@include phone {
    .CreateRole {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        padding: 60px 15px;
        input {
            margin-bottom: 20px;
        }
    }

    .title {
        margin-bottom: 10px;
    }

    .subtitle {
        margin-bottom: 20px;
    }

    .link {
        position: relative;
    }

    .back {
        left: 0px;
        top: -40px;
    }

    .picture_loader {
        width: 180px;
        height: 180px;
        margin-bottom: 20px;
    }

    .remove_btn {
        top: 5px;
        right: 5px;
        width: 30px;
        height: 30px;
    }
    .checkbox {
        font-size: 16px;
    }
}
