@import "var.scss";

.GenderControl {
    position: relative;
    display: flex;
    align-items: baseline;
    margin-right: 20px;

    input {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
    }
}
.label {
    margin-right: 16px;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border: 1px solid #d3d5d7;
    border-radius: 50%;
    margin-right: 5px;
    box-shadow: inset 0 0 0 -1px $accent;
    transition: all 0.3s;
    cursor: pointer;
    &.active {
        color: #fff;
        border-color: $accent;
        box-shadow: inset 0 0 0 25px $accent;
    }
    &:hover {
        border-color: $accent;
    }
}

@include phone {
    .GenderControl {
        margin-right: 10px;
    }
    .label {
        display: none;
    }

    .btn {
        font-size: 16px;
        width: 35px;
        height: 35px;
    }
}
