@import "var.scss";

.CreateProfile {
    padding: 72px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    margin: 173px 0 40px;
}

.role_selector {
    display: flex;
    justify-content: space-around;
    width: 660px;
    margin: 0 auto;
}

.role {
    padding: 28px 39px;
    text-align: center;

    &:hover {
        box-shadow: 0 0 0 1px $accent;
        border-radius: 4px;
        cursor: pointer;

        .icon {
            background-color: $accent;
        }
    }

    
}

.role_name {
    display: block;
    font-size: 14px;
    line-height: 19px;
    padding-bottom: 40px;
}

.icon {
    width: 116px;
    height: 116px;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center center;
    background-color: #F1F1F1;
    transition: background-color .3s;
}

@include phone {
    .CreateProfile {
        padding: 40px 0 80px;
    }
    
    .title {
        margin: 40px 0 20px;
    }
    
    .role_selector {
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        padding: 0 15px;
    }
    
    .role {
        padding: 15px;
        text-align: center;
        box-shadow: 0 0 0 1px #eeeeee;
        margin-bottom: 15px;
        border-radius: 4px;
        cursor: pointer;
    }
    
    .role_name {
        display: block;
        font-size: 16px;
        line-height: 19px;
        padding-bottom: 20px;
    }
    
    .icon {
        margin: 0 auto;
        width: 80px;
        height: 80px;
    }
}