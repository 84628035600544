@import "var.scss";

.UsersLog {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 24px;
  line-height: 33px;
  color: $text;
}

.header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;

  margin: -5px -5px 15px;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.select_wrapper {
  flex: 1 0 100%;
  margin: 5px 5px;

  span {
    margin-right: 10px;
  }
}

.datepickers {
  flex: 1 0 100%;
  margin: 5px 5px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.datepicker_wrapper {
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
  }

  & + .datepicker_wrapper {
    margin-left: 15px;
  }
}

.inviteWrapper {
  display: flex;

  margin-bottom: 40px;
}

.btn_submit {
  flex: 0 1 252px;
  margin-left: 10px;
}

.btn {
  height: 48px;
}

.search_wrapper {
  margin-bottom: 25px;
}

.list {
  margin-bottom: auto;
}

.loader {
  width: 300px;
  margin: 50px auto 0;
}

.status_loader {
  align-self: center;
  width: 100%;
  height: 28px;
  margin-top: 15px;
}

@include phone {
  .header {
    flex-direction: column;
    margin: 20px -5px;
  }

  .title {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 20px;
    text-align: center;
    align-self: stretch;
  }

  .select_wrapper {
    margin-bottom: 15px;
    display: inline-block;
    width: 100%;
    max-width: 500px;
    flex: 0 0 auto;
  }

  .datepickers {
    flex: 0 0 auto;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .datepicker_wrapper {
    margin-bottom: 15px;

    flex: 0 0 auto;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    span {
      margin-bottom: 5px;
      font-size: 16px;
    }

    & + .datepicker_wrapper {
      margin-left: 0;
    }
  }

  .titleWrapper {
    margin-bottom: 15px;
    flex-direction: column;
    align-items: flex-end;
  }

  .inviteWrapper {
    flex-direction: column;
  }

  .btn_submit {
    flex-basis: 42px;
    margin: 20px 0 0;
  }

  .header {
    flex-direction: column;
    margin-bottom: 30px;
  }

  .list {
    overflow-x: scroll;
  }
  .loader {
    width: 260px;
  }
}
