@import "var.scss";

.TestNavigation {
    padding: 28px 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

.section {
    width: 175px;
    text-align: center;
    opacity: 0.5;

    &:first-child {
        .left_line {
            opacity: 0;
        }
    }

    &:last-child {
        .right_line {
            opacity: 0;
        }
    }

    &.active {
        opacity: 1;

        .number {
            border-color: $accent;
        }

        .left_line,
        .right_line {
            background-color: $accent;
        }
    }
}

.icon {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.text {
    padding: 0 6px;
    font-size: 14px;
    line-height: 19px;
}

.number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border: 3px solid #aaaaaa;
    border-radius: 50%;
}

.left_line,
.right_line {
    flex-grow: 1;
    height: 4px;
    background-color: #aaaaaa;
}

@include phone {
    .TestNavigation {
        justify-content: stretch;
        padding: 25px 0 40px;
        max-width: 500px;
        margin: 0 auto;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .section {
        min-width: 120px;
    }

    .icon {
        margin-bottom: 3px;
    }

    .text {
        padding: 0 6px;
        font-size: 12px;
        line-height: 16px;
    }

    .number {
        font-size: 14px;
        width: 30px;
        height: 30px;
    }
}
