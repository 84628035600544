@import "./var.scss";

body {
    margin: 0;

    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: $text;

    &.no-scroll {
        height: 100%;
        overflow: hidden;
        position: relative;
    }
}

*,
*::after,
*::before {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    line-height: 150%;
    font-weight: 600;
}

.customHTMLblock {
    color: $text;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        padding: initial;
        line-height: 150%;
        font-weight: 600;
    }

    table {
        margin: 0 auto;
        border: 1px solid #eeeeee;
        border-collapse: collapse;
        th, td {
            border: 1px solid #eeeeee; 
            padding: 7px 10px;
        }

        th {
            background-color: #eeeeee;
        }
    }
    figure {
        margin: 25px 0;
    }
}
// отступ для панели инструмамнтов редактора статей
.ck-sticky-panel__content_sticky {
    top: 60px !important;
}

p {
    margin: 0;
    padding: 0;
}

hr {
    background-color: $hr;
    height: 1px;
    border: none;
    margin: 0;
}

a {
    color: $accent;
    text-decoration: none;
}

::-webkit-scrollbar {
    /* скроллбар */
    width: 8px;
    height: 8px;
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    /* ползунок */
    border-radius: 4px;
    background-color: $scrollbar;
}