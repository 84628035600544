@import "var.scss";

.ProviderEdit {
  flex-grow: 1;
  padding: 20px 40px;
}
.title {
  font-size: 24px;
  line-height: 33px;
  color: $text;
  margin-bottom: 22px;
}
.back {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  & > span {
    margin-left: 14px;
  }
}
.form {
  border: 1px solid #eeeeee;
  border-radius: 4px;
}
.picture_loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 200px;
  height: 200px;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  border: 1px dashed #d3d5d7;
  border-radius: 4px;
}

.picture_input {
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.add_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
  }
  &__text {
    margin-top: 12px;
  }
}
.remove_btn {
  position: absolute;
  z-index: 10;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #3f3f3f;
  border-radius: 4px;
  cursor: pointer;
}
.inner {
  margin-bottom: 10px;
  text-align: center;

  &.inner_active {
    min-width: 650px;
    margin-bottom: 20px;
  }

  .select {
    text-align: left;
  }
}
.section_title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: normal;
  font-size: 19px;
  line-height: 25px;
  padding: 18px 0 18px 70px;
  border-bottom: 1px solid #eeeeee;
  h5 {
    flex: 1 1 auto;
    font-weight: normal;
    font-size: 19px;
    line-height: 25px;
    display: inline-block;
  }
}
.block_btn {
  margin-right: 22px;
  font-size: 18px;
  line-height: 25px;
  color: #ff4949;
  cursor: pointer;
}
.section_content {
  padding: 28px 70px;
  width: 100%;
}
.section {
  padding: 28px 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.section_withPhoto {
  padding: 28px 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.inputs {
  width: 100%;
  max-width: 426px;
  input {
    margin-bottom: 20px;
  }
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dataFetchStatus {
  font-size: 12px;
  line-height: 16px;
  .error_status {
    color: $invalid;
  }
  .success_status {
    color: $btn-hover;
  }
}
.checkbox {
  display: block;
  margin-bottom: 20px;

  input {
    display: none;

    &:checked ~ .checkmark {
      background-color: $accent;

      &::after {
        display: block;
        left: 10px;
        top: 6px;
        width: 9px;
        height: 16px;
        border: solid #fff;
        border-width: 0 5px 5px 0;
        transform: rotate(45deg);
      }
    }
  }
}

.checkmark {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  width: 30px;
  height: 30px;
  border: 1px solid #d3d5d7;
  border-radius: 4px;
  margin-right: 14px;

  &:hover {
    background-color: #f0f1f1;
    cursor: pointer;
  }
  &::after {
    content: "";
    position: absolute;
    display: none;
  }
}

// --------------------- MEDIA

@include phone {
  .ProviderEdit {
    padding: 25px 0px;
  }
  .title {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 20px;
    text-align: center;
  }
  .back {
    margin-bottom: 15px;
    margin-left: 27px;
    & > span {
      margin-left: 10px;
    }
  }
  .picture_loader {
    width: 150px;
    height: 150px;
    align-self: center;
    margin-bottom: 20px;
  }
  .add_btn {
    font-size: 14px;
    &__text {
      margin-top: 0;
    }
  }
  .remove_btn {
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
  }
  .inner {
    &.inner_active {
      min-width: 100%;
      margin-bottom: 20px;
    }
  }
  .section_title {
    flex-direction: column-reverse;
    font-size: 16px;
    line-height: 22px;
    padding: 15px 27px 15px 27px;
  }
  .block_btn {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .section_content {
    padding: 20px 27px;
    width: 100%;
  }
  .section {
    padding: 20px 27px;
  }
  .section_withPhoto {
    padding: 20px 27px;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
  .inputs {
    width: 100%;
    max-width: none;
  }
  .checkbox {
    font-size: 16px;
  }
}
