@import "var.scss";

.content {
    max-width: 1160px;
    margin: 0 auto;
    width: 100%;
    background-color: $bg;
    display: flex;
    flex-direction: column;
    min-height: 964px;
}

@include phone {
    min-height: 0; 
}