@import "var.scss";

.Providers {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px 44px 0px;
}

.title {
  font-size: 24px;
  line-height: 33px;
  color: $text;
}
.titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search_wrapper {
  margin-bottom: 25px;
}

.list {
  margin-bottom: auto;
}

.btn_wrapper {
  width: 100%;
}

.loader {
  margin: 60px auto 0;
  width: 300px;
}

@include phone {
  .Providers {
    padding: 20px 15px;
  }

  .title {
    font-size: 20px;
    line-height: 27px;
    color: $text;
    align-self: center;
    margin-bottom: 15px;
  }
  .titleWrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 20px;
  }

  .list {
    overflow-x: scroll;
  }

  .btn_wrapper {
    width: 100%;
  }
  .loader {
    width: 260px;
  }
}
