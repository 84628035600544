@import "var.scss";
.ArticleSmall {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    padding: 27px 38px;
    color: inherit;
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 4px;

    .title {
        font-size: 20px;
        // ограничение в 2 строки
        flex-grow: 1;
        margin-bottom: 8px;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        -ms-line-clamp: 2;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        display: -webkit-box;
        word-wrap: break-word;
        -webkit-box-orient: vertical;
        box-orient: vertical;
    }

    .date {
        color: #c4c4c4;
    }

    .excerpt {
        // ограничение в 2 строки
        height: max-content;
        line-height: 28px;
        margin-bottom: 10px;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        -ms-line-clamp: 2;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        display: -webkit-box;
        word-wrap: break-word;
        -webkit-box-orient: vertical;
        box-orient: vertical;
    }

    .image {
        height: 150px;
        border-radius: 4px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
}
.ArticleLongAdmin {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 14px 24px;
    color: inherit;
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    .textBody_wrapper {
        width: 75%;
        margin-right: 30px;
    }
    .title {
        font-size: 20px;

        flex-grow: 1;
        margin-bottom: 10px;
        word-break: break-word;
    }

    .date {
        color: #c4c4c4;
        margin-bottom: 2px;
    }

    .excerpt {
        margin-bottom: 20px;
        max-height: 56px;
        height: max-content;

        // Ограничение в 2 строки
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        -ms-line-clamp: 2;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        display: -webkit-box;
        word-wrap: break-word;
        -webkit-box-orient: vertical;
        box-orient: vertical;
    }
    .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        &__edit,
        &__delete {
            font-size: 18px;
            line-height: 28px;
            font-family: "Open sans", sans-serif;
        }
        &__edit {
            color: $text;
            &:hover {
                color: black;
                cursor: pointer;
            }
        }
        &__delete {
            border: none;
            outline: none;
            background: white;
            color: $error;
            &:hover {
                color: red;
                cursor: pointer;
            }
        }
        &__ellipse {
            width: 4px;
            height: 4px;
            background: black;
            border-radius: 50%;
            margin-right: 24px;
            margin-left: 24px;
        }
    }
    .image {
        width: 350px;
        height: 150px;
        border-radius: 4px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
}

@include phone {
    .ArticleSmall {
        min-width: 260px;
        padding: 11px;
        width: auto;

        .title {
            // ограничение в 1 строку
            margin-bottom: 5px;
            -ms-line-clamp: 1;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            font-size: 14px;
            line-height: 17px;
        }

        .date {
            font-size: 13px;
            line-height: 13px;
            margin-bottom: 5px;
        }

        .excerpt {
            // ограничение в 2 строки
            font-size: 13px;
            line-height: 16px;
            margin-bottom: 5px;
        }

        .image {
            height: 102px;
        }
    }

    .ArticleLongAdmin {
        flex-direction: column-reverse;
        padding: 11px;

        .textBody_wrapper {
            display: flex;
            flex-direction: column;
            width: auto;
            margin-right: 0;
        }
        .title {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 10px;
            // ограничение в 2 строки
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
            overflow: hidden;
            -ms-line-clamp: 2;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            display: -webkit-box;
            word-wrap: break-word;
            -webkit-box-orient: vertical;
            box-orient: vertical; 
        }

        .date {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 0;
        }

        .excerpt {
            margin-bottom: 20px;
            max-height: none;
            font-size: 16px;
            line-height: 19px;
        }
        .buttons {
            align-self: center;
            &__edit,
            &__delete {
                padding: 0;
                font-size: 16px;
                line-height: 24px;
            }
        }
        .image {
            width: 100%;
            margin-bottom: 10px;
            height: 150px;
        }
    }
}
