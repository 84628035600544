@import "var.scss";

.Card {
    border: 1px solid #eeeeee;
    border-radius: 4px;
    margin-bottom: 20px;
    transition: border-color 0.3s;
    &:hover {
        cursor: pointer;
        border-color: $accent;

        .header {
            border-bottom-color: $accent;
        }

        .state {
            border-left-color: $accent;
        }
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
    transition: border-color 0.3s;
}

.header_info {
    display: flex;
    flex-direction: row;
}

.title {
    flex-grow: 1;
    padding: 14px 24px;
}
.info {
    padding: 14px 24px;
}

.deleteBtn {
    color: #c4c4c4;
    padding: 14px 58px;
    border-left: 1px solid #eeeeee;
    transition: border-color 0.3s;
    &:hover {
        color: $error;
    }
}

.main {
    padding: 10px 24px;
}

.docType {
    display: flex;
    flex-direction: column;
    width: 49%;
}

.file {
    padding-top: 16px;
    display: flex;
    align-items: center;
}

@include phone {
    .Card {
        margin-bottom: 15px;
        font-size: 16px;
    }

    .header {
        align-items: stretch;
    }
    
    .header_info {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
    .title {
        padding: 6px 0 0 10px;
    }
    .info {
        padding: 0 0 10px 10px;
        font-size: 13px;
        line-height: 13px;
        color: #c4c4c4;
    }

    .deleteBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 15px;
        margin-left: 10px;
        min-width: 100px;
    }

    .main {
        padding: 10px 10px 0;
    }

    .docType {
        line-height: 24px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .file {
        padding-top: 10px;
        margin-bottom: 10px;
        width: 100%;
    }
}
