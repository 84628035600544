@import "var.scss";
.Reports {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  max-width: 100%;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  margin-bottom: 30px;
}
.tabs {
  height: 50px;
  padding-top: 5px;
  margin-right: 15px;
}

.download_icon {
  margin-right: 5px;
}
.btn {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 7px;
  font-size: 18px;
  line-height: 24px;
  border: none;
  outline: none;
  background-color: inherit;
  text-align: center;
  border-radius: 4px;
  transition: all 0.3s;
  path {
    transition: all 0.3s;
  }
  &:hover:not([disabled]) {
    cursor: pointer;
    color: $accent;
    path {
      fill: $accent;
    }
  }
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
    svg {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  &_text {
    margin-left: 10px;
  }
}

@include phone {
  .Reports {
    padding: 10px 20px;
  }
  .header {
    margin-bottom: 10px;
  }
  .tabs {
    // height: 100%;
    padding: 0;
    margin-right: 0;

    overflow-x: scroll;
    overflow-y: hidden;
  }
  .btn {
    margin: 10px 0 0;
  }
}
