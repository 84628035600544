@import "var.scss";

.FaqCard {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 204px;
    max-width: 204px;
    padding: 9px 23px;

    font-size: 14px;
    line-height: 22px;
    color: inherit;

    background: transparent;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
}

.question {
    // ограничение в 2 строки
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    -ms-line-clamp: 2;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    box-orient: vertical;
}

.FaqCard + .FaqCard {
    margin-left: 20px;
}

@include phone {
    .FaqCard {
        min-width: 204px;
    }
    .FaqCard + .FaqCard {
        margin-left: 20px;
    }
}
