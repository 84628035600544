@import "var.scss";

.Input {
  position: relative;
  width: 100%;
  input {
    outline: none !important;
    height: 48px;
    width: 100%;
    border: 1px solid #d3d5d7;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 12px 23px 11px;
    font-size: 18px;
    line-height: 25px;
    font-family: "Open sans", sans-serif;
    &::placeholder {
      color: #c4c4c4;
    }
  }

  &:hover:not(.disabled),
  &:focus {
    input {
      border-color: $accent !important;
    }

    label {
      color: $accent !important;
    }
  }

  &.invalid {
    input {
      border-color: $invalid;
      box-shadow: none;
      margin-bottom: 2px !important;
    }
    label {
      color: $invalid;
    }
  }

  label {
    position: absolute;
    left: 18px;
    top: -10px;
    background-color: #ffffff;
    padding: 0 5px;
    font-size: 14px;
    line-height: 19px;

    color: #d3d5d7;
  }
}

.msg {
  display: block;
  font-size: 12px;
  line-height: 16px;
  padding-right: 10px;
  margin: 2px 0 20px;

  color: $invalid;
}

.symbolIcon {
  position: absolute;
  right: 8px;
  top: 6px;
  font-size: 24px;
  line-height: 33px;
  background: white;
  padding-left: 8px;
}

@include phone {
  .Input {
    input {
      height: 42px;
      padding: 12px 15px 11px;
      font-size: 16px;
      line-height: 22px;
    }

    label {
      left: 10px;
      font-size: 12px;
    }
  }

  .msg {
    margin: 2px 0 10px;
  }

  .symbolIcon {
    right: 6px;
    top: 5px;
    font-size: 20px;
  }
}
