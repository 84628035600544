@import "var.scss";

.EmptyPageFiller {
    text-align: center;
    padding-top: 60px;
    min-height: 740px;
}

.title {
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 7px;
}

@include phone {
    .EmptyPageFiller {
        text-align: center;
        padding: 40px 5px 0;
        min-height: 450px;
    }
    
    .title {
        font-weight: normal;
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 4px;
    }   
    .subtitle {
        font-size: 16px;
        line-height: 22px;
    }
}
