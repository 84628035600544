@import "var.scss";

.FileControl {
    line-height: 25px;
    display: flex;
    flex-wrap: wrap;
}

.add_file {
    height: 38px;
    display: inline-flex;
    align-items: stretch;
    border: 2px solid #f5f5f5;
    border-radius: 4px;
    transition: border-color .3s;
    // margin: 0 20px 20px 0;
}

.disabled {
    display: none;
}

.add_file {
    &:hover {
        cursor: pointer;
        border-color: $accent;
        .add {
            background-color: $accent;
        }
        .label {
            border-color: $accent;
        }
    }
}

.label {
    padding: 3px 33px;
    border-left: 2px solid #f5f5f5;
    transition: border-color .3s;
    // height: 38px;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
}

.input {
    width: 0px;
    height: 0px;
    visibility: hidden;
}

.add {
    width: 58px;
    height: 34px;
    mask-repeat: no-repeat;
    mask-position: center center;
    background-color: #f5f5f5;
    transition: background-color 0.3s;
    mask-size: 20px 20px;
}

@include phone {
    .FileControl {
        line-height: 22px;
        font-size: 14px;
        &>* {
            margin-bottom: 5px;
        }
    }
    
    .label {
        padding: 6px 10px;
    }
    
    
    .add {
        width: 34px;
        height: 34px;
    }
}
