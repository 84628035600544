@import "var.scss";

.UserTable {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;

  th {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $accent;
    text-align: left;
    padding: 10px 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  td {
    padding: 10px 5px;
  }
}

.name_head {
  width: 700px;
}

.spec_head {
  width: 750px;
}

.birth_head {
  width: 400px;
}

.email_head {
  width: 500px;
}

.status_head {
  width: 300px;
}

.deleted_head {
  width: 200px;
}

@include phone {
  .UserTable {
    max-width: 700px;
    min-width: 500px;
    margin: 0 auto;
    font-size: 11px;
    line-height: 16px;

    th {
      font-size: 11px;
      line-height: 16px;
    }
  }
}
