@import "var.scss";

.DocumentItem {
    width: 435px;
}

.header {
    color: #c4c4c4;
    margin-bottom: 6px;
}

.title {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 10px;
}

.type {
    margin-bottom: 10px;
}

@include phone {
    .DocumentItem {
        font-size: 16px;
        line-height: 22px;
        width: 435px;
    }
    
    .header {
        font-size: 14px;
        line-height: 19px;
       margin-bottom: 3px;
    }
    
    .title {
        margin-bottom: 7px;
    }
}