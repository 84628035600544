@import "var.scss";

.section {
  padding: 0 43px;
  background: linear-gradient(180deg, #ffffff 0%, #f9f9f9 100%);
}

.intro_container {
  max-width: 1173px;
  margin: 0 auto;
  padding: 80px 0px 50px;
}

.header {
  margin-bottom: 40px;
}

.intro {
  display: flex;
  justify-content: space-between;
  margin-bottom: 120px;
}

.company {
  flex: 1 0 450px;
  display: flex;
  flex-direction: column;
  max-width: 533px;
  padding-right: 60px;
}

.company_name {
  font-size: 28px;
  line-height: 38px;
  margin-bottom: 13px;
  text-transform: uppercase;
}

.intro_subtitle {
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 10px;
}

.intro_list {
  margin-bottom: 10px;
}

.intro_item {
  display: flex;
  align-items: center;
  height: 80px;
}

.item_label {
  margin-right: 23px;
}

.item_text {
  font-size: 13px;
  line-height: 20px;
  max-width: 322px;
}

.intro_tip {
  font-size: 12px;
  line-height: 25px;
  margin-bottom: 5px;
  white-space: pre-wrap;
}

.list {
  margin: 0;
  margin-bottom: 20px;
}

.join_btn {
  max-width: 426px;
}

.services {
  flex: 0 1 516px;
  display: flex;
  flex-direction: column;
}

.service_snippet {
  display: flex;
  align-items: stretch;
  margin-bottom: 60px;
}

.service_snippet:last-child {
  margin-bottom: 0;
}

.snippet_icon {
  padding: 45px 50px 0 0;
}

.snippet_body {
  cursor: pointer;
}

.snippet_content {
  color: inherit;
}

.snippet_title {
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 30px;
}

.snippet_description {
  max-width: 350px;
  width: max-content;
  margin-bottom: 30px;
}

.doctors {
  display: flex;
  flex-direction: column;
}

.doctors_title {
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 48px;
  text-align: center;
}

.doctors_list {
  display: flex;
  justify-content: stretch;
  margin-bottom: 20px;
}

.more {
  color: inherit;
  align-self: flex-end;
  font-size: 14px;
  line-height: 19px;
  img {
    margin-left: 10px;
  }
}

.articles {
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  max-width: 1440px;
  margin: 0 auto;
}

.articles_title {
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  margin-bottom: 38px;
}

.articles_list {
  display: grid;
  column-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 20px;
}

.benefits_container {
  max-width: 1120px;
  margin: 0 auto;
}

.capabilities {
  padding: 120px 0 66px;
}

.capabilities_title {
  text-align: center;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 50px;
}

.capabilities_list {
  display: flex;
  justify-content: space-between;
}

.capabilities_item {
  display: flex;
  align-items: center;
}

.capabilities_number {
  position: relative;
  height: 105px;
  font-size: 34px;
  line-height: 24px;
  color: $accent;
  span {
    position: absolute;
    top: 36px;
    left: 40px;
  }
  img {
    height: 100%;
  }
}

.capabilities_text {
  transform: translateX(-6px);
  max-width: 240px;
  font-size: 16px;
  line-height: 24px;
}

.authorization {
  max-width: 398px;
  margin: 0 auto 120px;
  text-align: center;
}

.auth_btn {
  margin-bottom: 28px;
}

.hr {
  margin-bottom: 28px;
}

.faq {
  display: flex;
  flex-direction: column;
  padding: 0 20px 50px;
}

.faq_title {
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  margin-bottom: 54px;
}

.faq_list {
  margin: 0 auto;
  max-width: 980px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

@include _1170 {
  .Main {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
  }
  .intro {
    flex-direction: column;
    margin-bottom: 80px;
  }
  .company {
    margin-bottom: 80px;
    padding: 0;
  }
  .services {
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: space-between;
  }
  .service_snippet {
    max-width: 50%;
    flex-direction: column;
    text-align: center;
    margin: 0;
    padding-right: 30px;
  }

  .service_snippet:last-child {
    padding: 0;
    padding-left: 30px;
  }

  .snippet_icon {
    padding: 0;
    margin-bottom: 20px;
  }

  .snippet_content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    color: inherit;
  }

  .snippet_title {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 15px;
  }

  .snippet_description {
    flex: 1 0 auto;
    font-size: 16px;
    margin-bottom: 15px;
    width: 100%;
    max-width: none;
  }
}

@include phone {
  // Первая секция

  .section {
    padding: 0 15px;
  }

  .intro_container {
    max-width: 1126px;
    margin: 0 auto;
    padding: 25px 0;
  }

  .intro {
    display: block;
    margin-bottom: 30px;
  }

  .company {
    display: block;
  }

  .company_name {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 10px;
  }

  .intro_subtitle {
    font-size: 16px;
    line-height: 24px;
  }

  .intro_list {
    margin-bottom: 20px;
  }

  .item_label {
    margin-right: 15px;
  }

  .item_text {
    max-width: 400px;
  }

  .intro_tip {
    font-size: 11px;
    line-height: 18px;
    margin-bottom: 5px;
    white-space: pre-wrap;
  }

  .join_btn {
    max-width: 450px;
  }

  .services {
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
  }

  .service_snippet {
    max-width: 500px;
    margin-bottom: 60px;
    padding: 0;
  }

  .service_snippet:last-child {
    padding-left: 0;
  }

  .doctors_title {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 20px;
    text-align: left;
  }

  .doctors_list {
    display: flex;
    justify-content: stretch;
    margin: 0 -15px 30px;
    padding: 0 15px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    // для отступа справа
    &::after {
      display: block;
      content: " ";
      padding: 8px;
    }
  }

  .more {
    align-self: center;
    padding: 0 !important;
  }

  // Вторая секция

  .articles {
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
  }

  .articles_title {
    font-size: 20px;
    line-height: 27px;
    text-align: left;
    margin-bottom: 15px;
  }

  .articles_list {
    display: flex;
    justify-content: stretch;
    overflow-x: scroll;

    margin: 0 -15px 30px;
    padding: 0 15px;

    &::-webkit-scrollbar {
      display: none;
    }

    a + a {
      //отступы между соседними сниппетами
      margin-left: 20px;
    }
    // для отступа справа
    &::after {
      display: block;
      content: " ";
      padding: 8px;
    }
  }

  // Третья секция

  .benefits_container {
    max-width: none;
    width: 100%;
    margin: 0;
  }

  .capabilities {
    padding: 0;
    padding-top: 40px;
  }

  .capabilities_title {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 15px;
  }

  .capabilities_subtitle {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 25px;
  }

  .capabilities_list {
    display: flex;
    justify-content: stretch;
    align-items: center;
    flex-direction: column;
  }

  .capabilities_item {
    margin-bottom: 40px;
  }

  .capabilities_number {
    height: 90px;
    span {
      position: absolute;
      top: 32px;
      left: 35px;
    }
    img {
      height: 100%;
    }
  }

  .capabilities_text {
    font-size: 12px;
    line-height: 20px;
  }

  .authorization {
    max-width: 398px;
    margin: 0 auto 57px;
    text-align: center;
    font-size: 14px;
    line-height: 19px;
  }

  .faq {
    padding: 0;
    padding-bottom: 25px;
  }

  .faq_title {
    font-size: 20px;
    line-height: 27px;
    text-align: left;
    margin-bottom: 20px;
  }

  .faq_list {
    justify-content: stretch;
    overflow-x: scroll;
    padding: 0 15px;
    margin: 0 -15px;
    margin-bottom: 30px;

    &::-webkit-scrollbar {
      display: none;
    }

    // для отступа справа
    &::after {
      display: block;
      content: " ";
      padding: 8px;
    }
  }
}
