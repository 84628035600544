@import 'var.scss';

.Logo {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_text {
        color: $text;
        font-size: 14px;
        line-height: 19px;
    }
}