@import "var.scss";

.FeedbackItem {
    padding: 14px 24px 20px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
}

.date {
    color: #c4c4c4;
    margin-bottom: 8px;
}

.title {
    font-weight: 700;
    margin-bottom: 10px;
}
.text {
    overflow-x: hidden;
}

.toggle {
    cursor: pointer;
    align-self: flex-end;
    color: $accent;
    font-size: 14px;
    padding: 5px;
}

@include phone {
    .FeedbackItem {
        padding: 14px 10px 15px;
    }

    .date {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 3px;
    }

    .title {
        margin-bottom: 7px;
    }
}
