@import "var.scss";

.select > div {
  font-family: Arial, Helvetica, sans-serif;

  svg {
    width: 24px;
    height: 24px;
  }
}

.select > div > div {
  min-height: 50px;
}

.select > div > div:nth-child(2) {
  min-height: 50px;
}
