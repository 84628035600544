@import "var.scss";

.TestResultItem {
    border: 1px solid #eeeeee;
    border-radius: 4px;
    max-width: 435px;
}

.header {
    display: flex;
    border-bottom: 1px solid #eeeeee;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    border-right: 1px solid #eeeeee;
    padding: 12px 15px;
}

.date {
    color: #c4c4c4;
    font-size: 14px;
    line-height: 19px;
}

.type {
    width: 120px;
    padding: 25px 10px;
    text-align: center;
    color: #c4c4c4;
    text-transform: uppercase;
}
.file {
    padding: 18px 15px;
}

@include phone {
    .TestResultItem {
        margin-bottom: 10px;
    }
    .date {
        font-size: 16px;
    }
}
