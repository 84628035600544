@import "var.scss";

.Faq {
    min-height: 568px;
    max-width: 1126px;
    margin: 0 auto 8px;

    & > * {
        // для всех дочерних
        margin-bottom: 20px;
    }
}

.title {
    margin: 48px 0;
    text-align: center;
    font-size: 24px;
    line-height: 33px;
    font-weight: normal;
}

@include phone {
    .Faq {
        min-height: 320px;
        max-width: 600px;
        margin: 0 auto;
        padding: 0px 15px;

        & > * {
            // для всех дочерних
            margin-bottom: 15px;
        }
    }

    .title {
        margin: 0;
        padding: 25px 0 15px;
        font-size: 20px;
        line-height: 27px;
    }
}
