@import "var.scss";

.NewConsultation {
    flex-grow: 1;
    padding: 17px 48px 48px 40px;
}

.back {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    & > span {
        margin-left: 14px;
    }
}

.title {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 28px;
    font-weight: normal;
}

.form {
    border: 1px solid #eeeeee;
    border-radius: 4px;
}

.section_title {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    padding: 18px 0 18px 70px;
    border-bottom: 1px solid #eeeeee;
}

.type_section {
    padding: 28px 70px;
}

.description_section {
    padding: 28px 70px 28px 70px;
}

.textarea {
    outline: none;
    width: 100%;
    height: 150px;
    border: 1px solid #d3d5d7;
    border-radius: 4px;
    padding: 12px 23px;
    margin-bottom: 20px;
    resize: none;
    overflow-y: scroll;

    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    &:hover, &:focus {
        border-color: $accent;
    }
}

.select_box {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    margin-bottom: 20px;
}

.extra_section {
    padding: 28px 70px 48px;
}

.btn_link {
    max-width: 230px;
    display: block;
}


.download {
    margin-bottom: 40px;
}

.text {
    margin-bottom: 28px;
    margin-right: 20px;
}

.download_description {
    font-size: 14px;
    line-height: 19px;
    max-width: 370px;
    margin-bottom: 27px;
}

.attention {
    max-width: 470px;
    margin-bottom: 20px;
}


@include phone {
    .NewConsultation {
        padding: 25px 15px 30px 15px;
    }
    
    .back {
        margin-bottom: 15px;
    }
    
    .title {
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 20px;
    }

    .form {
        margin: 0 -15px;
    }
    
    .section_title {
        font-size: 11px;
        line-height: 15px;
        padding: 15px 0 15px 27px;
    }
    
    .type_section {
        padding: 15px 20px 15px 27px;
    }
    
    .description_section {
        padding: 15px 20px 15px 27px;
    }
    
    .textarea {
        padding: 7px 15px;
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 24px;
    }
    
    .select_box {
        grid-template-columns: repeat(1, 1fr);
        max-width: 450px;
        margin: 0 auto 15px;
    }

    .type {
        max-width: 450px;
        margin: 0 auto;
    }
    
    .extra_section {
        padding: 15px 27px 20px;
        font-size: 14px;
        line-height: 19px;
        max-width: 450px;
    }

    .btn_link {
        max-width: 100%;
        margin-bottom: 15px;
    }
    
    .download {
        margin-bottom: 20px;
    }
    
    .text {
        margin-bottom: 15px;
        margin-right: 0;
    }
    
    .download_description {
        font-size: 11px;
        line-height: 14px;
        max-width: 220px;
        margin-bottom: 15px;
    }
    
    .attention {
        font-size: 11px;
        line-height: 14px;
        max-width: 220px;
        margin-bottom: 15px;
    } 
}
