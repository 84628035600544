@import "var.scss";

.SignUp {
    padding-top: 40px;

    input:not([type="checkbox"]) {
        margin-bottom: 28px;
    }
}

.title {
    position: relative;
    text-align: center;
    font-size: 24px;
    line-height: 33px;

    margin-bottom: 33px;
    a {
        position: absolute;
        left: -72px;
        top: 0;
    }
}

.inner {
    padding-top: 65px;
}

.hr {
    margin: 28px 0;
}

.login {
    font-size: 14px;
    line-height: 19px;
    text-align: center;
}

.checkbox {
    display: block;
    margin-bottom: 16px;

    input {
        display: none;

        &:checked ~ .checkmark {
            background-color: $accent;

            &::after {
                display: block;
                left: 10px;
                top: 6px;
                width: 9px;
                height: 16px;
                border: solid #fff;
                border-width: 0 5px 5px 0;
                transform: rotate(45deg);
            }
        }
    }
}

.checkmark {
    position: relative;
    display: inline-block;
    vertical-align: bottom;
    width: 30px;
    height: 30px;
    border: 1px solid #d3d5d7;
    border-radius: 4px;
    margin-right: 14px;

    &:hover {
        background-color: #f0f1f1;
        cursor: pointer;
    }
    &::after {
        content: "";
        position: absolute;
        display: none;
    }
}

.link {
    display: block;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 19px;

    &:last-of-type {
        margin-bottom: 28px;
    }
}

.error {
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;

    color: $invalid;
}

@include phone {
    .SignUp {
        padding-top: 0px;

        input:not([type="checkbox"]) {
            margin-bottom: 16px;
        }
    }

    .title {
        font-size: 20px;
        line-height: 27px;

        margin-bottom: 25px;
        a {
            position: absolute;
            left: 0;
            top: auto;
            bottom: 0;
        }
    }

    .inner {
        padding-top: 40px;
    }

    .checkbox {
        margin-bottom: 10px;
    }

    .link {
        margin-bottom: 10px;
    }

}
