@import "../../../var.scss";

.CardStatus {
    margin-bottom: 30px;
    line-height: 25px;
    font-size: 16px;
}

.item {
    white-space: nowrap;
    padding: 2px 0;
    cursor: pointer;
    &:hover{
        color: #18d0c3;
    }
    & + & {
        margin-left: 20px;
    }

    &.active {
        color: $accent;
        box-shadow: inset 0 -1px 0 0 $accent;
    }
}

@include phone {
    .CardStatus {
        margin: 0 -15px;
        padding: 0 15px 20px;
        display: flex;
        font-size: 16px;
        overflow-x: scroll;
        
        &::-webkit-scrollbar {
            display: none;
        }
        // отступ справа
        &::after {
            display: inline;
            content: "";
            padding: 0 7px;
        }
    }

    .item {
        padding: 4px 0;

        & + & {
            margin-left: 20px;
        }
    } 
}
