@import "var.scss";

.Medcard {
  flex-grow: 1;
  padding: 20px 40px;
}

.title_active {
  font-size: 24px;
  line-height: 33px;
  color: #181818;
  margin-right: 45px;
}

.title {
  font-size: 24px;
  line-height: 33px;
  color: #181818;
  opacity: 0.5;
  cursor: pointer;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 34px;
}
.titleWrapper {
  display: flex;
}

@include phone {
  .Medcard {
    padding: 25px 15px;
  }

  .title_active {
    font-size: 20px;
    line-height: 27px;
    margin-right: 0;
    margin-bottom: 5px;
  }

  .title {
    font-size: 20px;
    line-height: 27px;
  }

  .header {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 15px;
  }
  .titleWrapper {
    flex-direction: column;
    margin-bottom: 20px;
  }
}
