.InviteRow {
    background-color: #ffffff;
    transition: background-color .3s;
    &:hover {
        background-color: #eeeeee;
        cursor: pointer;
    }
}

.missing {
    color: #aaaaaa
}