@import "var.scss";

.Test {
  padding-top: 72px;
  display: flex;
  flex-direction: column;
}

.title {
  position: relative;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  margin: 48px 0 10px;
  a {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.back {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.amount {
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 48px;
}

.intro,
.finish {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: 48px;
  p + p {
    margin-top: 20px;
  }
  p:last-of-type {
    margin-bottom: 48px;
  }
  .list {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.intro {
  max-width: 825px;
}

.finish {
  padding-top: 76px;
  max-width: 653px;
  text-align: center;
}

.warning {
  opacity: 0.6;
  font-size: 12px;
}

.results {
  text-align: left;
  margin-bottom: 30px;
}

.start_button,
.finish_button {
  align-self: center;
  width: 426px;
}

.container {
  flex-grow: 1;
  max-width: 1126px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 48px;
}

.button {
  width: 230px;
  margin-left: 116px;
}

.questionnaire {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  padding: 46px 3px 76px;
}

.questionnaire_number {
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.questionnaire_name {
  font-weight: 600;
  text-align: center;
  margin-bottom: 28px;
}

@include phone {
  .Test {
    padding-top: 30px;
  }

  .title {
    position: relative;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    margin: 40px 15px 10px;
    a {
      top: -40px;
      left: 0;
    }
  }

  .back {
    top: -40px;
    left: 0;
    cursor: pointer;
  }

  .amount {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 20px;
  }

  .intro,
  .finish {
    max-width: 500px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 19px;
    padding: 0 15px;
    padding-bottom: 0;
    p + p {
      margin-top: 10px;
    }
    p:last-of-type {
      margin-bottom: 15px;
    }
    .list {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  .list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .finish {
    padding-top: 20px;
    max-width: none;
    text-align: center;
  }

  .start_button,
  .finish_button {
    align-self: center;
    width: 100%;
    margin: 0 15px;
  }

  .container {
    padding-bottom: 20px;
  }

  .button {
    width: 230px;
    margin-left: 27px;
  }

  .questionnaire {
    padding: 20px 15px 40px;
    max-width: 600px;
    margin: 0 auto;
  }

  .questionnaire_name {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;
  }
  .questionnaire_info {
    font-size: 13px;
    line-height: 19px;
  }
}
