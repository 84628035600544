@import "var.scss";

.Login {
    padding-top: 40px;

    input[type="email"] {
        margin-bottom: 28px;
    }

    input[type="password"] {
        margin-bottom: 10px;
    }
}

.title {
    position: relative;
    text-align: center;
    font-size: 24px;
    line-height: 33px;

    margin-bottom: 33px;

    a {
        position: absolute;
        left: -72px;
        top: 0;
    }
}

.inner {
    padding-top: 100px;
}

.forgot {
    display: block;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 48px;
}

.hr {
    margin: 28px 0;
}

.create {
    font-size: 14px;
    line-height: 19px;
    text-align: center;
}

.error {
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;

    color: $invalid;
}

@include phone {
    .Login {
        padding-top: 0px;

        input[type="email"] {
            margin-bottom: 16px;
        }

        input[type="password"] {
            margin-bottom: 5px;
        }
    }

    .title {
        font-size: 20px;
        line-height: 27px;

        margin-bottom: 25px;

        a {
            position: absolute;
            left: 0;
            top: auto;
            bottom: 0;
        }
    }

    .inner {
        padding-top: 60px;
    }
}
