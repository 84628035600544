@import "var.scss";

// бэкграунд
.modalMask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}
// обертка
.modalWrapper {
  display: table-cell;
  vertical-align: middle;
}
// модальное окно
.modalContainer {
  cursor: default;
  position: relative;
  width: 100%;
  height: 60%;
  margin: 0px auto;
  padding: 40px 70px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // кнопка закрытия модалки
  .modalClose {
    position: absolute;
    top: 2%;
    right: 1%;
  }
}

@include phone {
  // бэкграунд
  .modalMask {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: calc(100vh + 60px);
    width: auto;
  }
  // обертка
  .modalWrapper {
    display: table-cell;
    vertical-align: middle;
    padding: 0 15px;
  }
  // модальное окно
  .modalContainer {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 20px 40px;
    // кнопка закрытия модалки
    .modalClose {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
