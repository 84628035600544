.react-dadata {
    position: relative;
    &__suggestions {
        position: absolute;
        top: 50px;
        width: 100%;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        border: 1px solid #eeeeee;
        border-radius: 4px;
    }
    &__suggestion {
        padding: 5px;
        border:none;
        background-color: inherit;
        &:hover {
            background-color: #eeeeee;
            cursor: pointer;
        }
    }
    &--highlighted {
        font-weight: bold;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}