@import "var.scss";

.ConsultationsLogRow {
  background-color: #ffffff;
  transition: background-color 0.3s;
}

.hoveredColumn {
  transition: color 0.3s;
  &:hover {
    color: $accent;
    cursor: pointer;
  }
}

.missing {
  color: #aaaaaa;
}
