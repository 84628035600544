@import "var.scss";

.ButtonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  &:hover > .plus:not([disabled]) {
    border-color: $btn-hover;
    color: $btn-hover;
    cursor: pointer;
  }
  .plus {
    border: 1px solid $accent;
    color: $accent;
    background-color: inherit;
    width: 32px;
    height: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    &::before {
      content: "+";
      font-size: 34px;
      font-family: "Open sans";
      font-weight: 100;
    }
  }
  .outlineText {
    font-size: 18px;
    line-height: 25px;
    color: $text;
    white-space: nowrap;
  }
  .disabledText {
    opacity: 0.6;
  }
}

.Button {
  width: 100%;
  padding: 7px;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  border: none;
  outline: none;
  background-color: inherit;
  text-align: center;
  border-radius: 4px;
  &:hover:not([disabled]) {
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.btn_large {
    height: 48px;
    margin: 0;
    padding: 12px;
  }

  &.btn_snippet {
    max-width: 253px;
  }
}

.primary {
  background-color: $accent;

  &:hover:not([disabled]) {
    background-color: $btn-hover;
    cursor: pointer;
  }
}

.additional {
  border: 1px solid $accent;
  padding: 6px;
  color: $accent;
  background-color: inherit;

  &:hover:not([disabled]) {
    border-color: $btn-hover;
    color: $btn-hover;
    cursor: pointer;
  }
}

.sidebar {
  border: 1px solid #fff;
  padding: 6px;
  color: $text;
  background-color: #fff;
}

.close {
  border: none;
  color: $text;
  background-color: inherit;
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: black;
  }
  &::before {
    content: "+";
    transform: rotate(45deg);
    font-size: 34px;
    font-family: "Open sans", sans-serif;
  }
}

@include phone {
  .Button {
    font-size: 16px;
  }
  .ButtonWrapper {
    .outlineText {
      font-size: 16px;
    }
    .plus {
      margin-right: 10px;
    }
  }
}
@include phone {
  .Button {
    &.btn_large {
      height: 42px;
      line-height: 20px;
    }
  }
}
