@import "var.scss";

.Consultations {
    flex-grow: 1;
    padding: 20px 40px;

}
.header {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.select_wrapper {
    display: inline-block;
    min-width: 400px;
}

.datepicker_wrapper {
    margin-left: 10px;
}

.select_text {
    margin-right: 20px;
    vertical-align: middle;
    margin-bottom: 5px;
}

.button_wrapper {
    width: 100%;
}

.loader {
    width: 300px;
    margin: 50px auto 0;
}

.status_loader {
    align-self: center;
    width: 100%;
    height: 28px;
    margin-top: 15px;
}

@include phone {
    .Consultations {
        padding: 25px 15px;
    }
    .header {
        flex-direction: column;
        margin-bottom: 30px;
    }
    .status_loader {
        height: 18px;
        margin-left: 0px;
        margin-top: 0;
        width: 100%;
    }
    
    .select_wrapper {
        display: inline-block;
        width: 100%;
        max-width: 500px;
        margin-bottom: 10px;
    }
    .select_wrapper {
        display: inline-block;
        min-width: 0;
    }  

    .datepicker_wrapper {
        margin-left: 0px;
        margin-bottom: 15px;
    }

    .loader {
        width: 260px;
    }
    
    .select_text {
        font-size: 14px;
        margin-right: 40px;
    }
}