@import "var.scss";

.ModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: #181818;
}
.subtitle {
  max-width: 600px;

  font-family: "Open Sans", sans-serif;
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}
.icon {
  margin-top: 28px;
  margin-bottom: 28px;
  width: 116px;
  height: 116px;
}

@include phone {
  .title {
    font-size: 20px;
    line-height: 27px;
  }
  .subtitle {
    font-size: 14px;
    line-height: 20px;
  }
  .buttons {
    width: 100%;
    margin: 0 15px;
    .btn {
      width: 100%;
    }
  }
  .icon {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
  }
}
