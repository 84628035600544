@import "var.scss";

.HealthStatus {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.result {
    display: flex;
    align-items: center;
}
.indicator {
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    margin-right: 5px;
}
.score {
    font-size: 18px;
    line-height: 18px;
}
.status_text {
    position: absolute;
    top: 18px;
    font-size: 12px;
    line-height: 14px;
    opacity: 0.5;
    white-space: nowrap;
}

.red {
    background-color: #ff3636;
}
.yellow {
    background-color: #ffff35;
}
.gray {
    background-color: #adadad;
}
.green {
    background-color: #1ec21e;
}

@include phone {
    .health_status {
        margin-bottom: 3px;
    }

    .score {
        font-size: 16px;
        line-height: 16px;
    }
}
