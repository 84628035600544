@import "var.scss";

.Feedback {
    padding: 60px 15px 48px;
}

.title {
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    font-size: normal;
    margin-bottom: 7px;
}

.subtitle {
    text-align: center;
    margin-bottom: 50px;
}

.form {
    border: 1px solid #eeeeee;
    border-radius: 4px;
    max-width: 740px;
    margin: 0 auto;
    padding: 48px 30px;
}

.wrapper {
    max-width: 426px;
    margin: 0 auto;
    input {
        margin-bottom: 20px;
    }
}

.textarea_wrapper {
    margin-bottom: 48px;
    margin-top: 10px;
}

.success, .error {
    font-size: 12px;
    line-height: 16px;
    margin-top: 5px;
}

.success{
    color: $accent;
}

.error {
    color: $error;
}

.limit {
    position: relative;
    height: 0px;
    color: #c4c4c4;
    font-size: 12px;
    line-height: 16px;
    span {
        position: absolute;
        right: 0;
        bottom: 0;
    }
}

@include phone {
    .Feedback {
        padding: 20px 15px 20px;
    }

    .title {
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 5px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 30px;
    }

    .form {
        padding: 25px 20px;
    }

    .wrapper {
        input {
            margin-bottom: 15px;
        }
    }
    .textarea_wrapper {
        margin-bottom: 30px;
    }
}
