@import 'var.scss';

.StatusTabs {
    display: flex;
    margin: 0 -15px;
}

.item {
    margin: 0 15px;
    padding-bottom: 4px;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    transition: all .3s;
}

.active {
    color: $accent;
    border-color: $accent;
}

@include phone {
    .StatusTabs {
        display: flex;
        margin: 0 -10px;

        &:after{
            content: "";
            display: block;
            padding-left: 5px;
        }
    }
    
    .item {
        font-size: 16px;
        white-space: nowrap;
        padding-bottom: 3px;
        margin: 0 10px;
    }
}
