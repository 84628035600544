@import "var.scss";

.HealthStatusModal {
    max-width: 526px;
    border-radius: 4px;
    background-color: #fff;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: -20px;
    height: calc(100vh + 60px);
    padding-top: 160px;
    overflow-y: scroll;
    background-color: rgba(63, 63, 63, 0.2);
    &::after {
        content: "";
        display: block;
        padding: 60px;
    }
}
.header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
    padding: 18px 27px 18px 32px;
}

.title {
    font-size: 18px;
    line-height: 25px;
    margin-left: 16px;
    flex-grow: 1;
}

.close {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 5px;
}

.color_indicator {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.red {
    .color_indicator {
        background-color: #ff3636;
    }
}
.yellow {
    .color_indicator {
        background-color: #ffff35;
    }
}
.gray {
    .color_indicator {
        background-color: #adadad;
    }
}
.green {
    .color_indicator {
        background-color: #1ec21e;
    }
}

.description {
    padding: 24px 32px;
    font-size: 14px;
    line-height: 24px;
    white-space: pre-wrap;
}

@include phone {
    .HealthStatusModal {
        max-width: 100%;
        border-radius: 0;
        min-height: 100%;
    }

    .wrapper {
        top: 0;
        left: 0;
        right: 0;
        height: calc(100vh + 60px);
        padding-top: 0;
        overflow-y: scroll;
        &::after {
            padding: 30px;
        }
    }
    .header {
        padding: 20px 10px 20px 15px;
    }

    .title {
        margin-left: 26px;
    }

    .color_indicator {
        width: 20px;
        height: 20px;
    }
    .description {
        padding: 24px 15px;
    }
    
}
