@import "var.scss";

.Representative {
    display: flex;
    align-items: flex-start;
}

.info {
    flex-grow: 1;
}

.name {
    font-weight: 600;
    margin-bottom: 4px;
}

.delete_btn {
    color: #EB5757;
    cursor: pointer;
    padding: 0 0 10px 10px;
}

.Representative + .Representative {
    margin-top: 28px;
}

@include phone {
    .Representative {
        display: flex;
        align-items: flex-start;
    }
    
    .info {
        font-size: 14px;
        line-height: 19px;
    }
    
    .name {
        margin-bottom: 3px;
    }
    
    .delete_btn {
        font-size: 14px;
        line-height: 19px;
    }
    
    .Representative + .Representative {
        margin-top: 15px;
    }
}