@import "var.scss";

.Journal {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 35px 40px;
}

.tabs {
    display: flex;
    margin-bottom: 27px;
}

.tab {
    font-size: 24px;
    line-height: 33px;
    margin-right: 53px;
    cursor: pointer;

    &.activeTab {
        opacity: 0.5;
    }
}

.sort {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}


.select {
    min-width: 300px;
    margin-left: 20px;
    margin-right: 20px;
    
    select {
        color: $text;
    }
}

.datepicker {
    display: flex;
    align-items: center;
    justify-content:stretch;
}

.btn_wrapper {
    margin-left: 20px;
    flex-basis: 200px;
}

.table_wrapper {
    margin-bottom: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
    line-height: 25px;
    th {
        text-transform: uppercase;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: $accent;
        text-align: left;
        padding: 10px 5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    td {
        padding: 10px 5px;
    }
}

.time_head,
.time_data {
    width: 188px;
    white-space: nowrap;
}

.name_head,
.name_data {
    width: 331px;
}

.email_head,
.email_data {
    width: 250px;
}

.role_head,
.role_data {
    width: 143px;
}

.action_head,
.action_data {
    width: 393px;
}

.empty {
    flex-grow: 1;
}

.loader {
    width: 300px;
    margin: 50px auto 0;
}

.search {
    display: flex;
    align-items: center;
    justify-content: stretch;
    margin-bottom: 35px;
}

.search_title {
    margin-right: 20px;
}
.search_input {
    flex-grow: 1;
    align-self: baseline;
}

@include phone {
    .Journal {
        padding: 25px 5px;
    }

    .tabs {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        margin-left: 10px;
    }

    .tab {
        font-size: 20px;
        line-height: 27px;
        margin-right: 0px;
        margin-bottom: 5px;
    }

    .sort {
        max-width: 400px;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin: 0 15px 20px 10px;
    }

    .btn_wrapper {
        margin-left: 10px;
        flex-basis: 150px;
    }

    .select {
        min-width: 0;
        width: 100%;
        margin-left: 0px;
        margin-bottom: 10px;
    }

    .table_wrapper {
        margin-bottom: auto;
        overflow-x: scroll;
    }

    .table {
        min-width: 600px;
        font-size: 11px;
        line-height: 16px;
        th {
            font-size: 11px;
            line-height: 16px;
        }
    }  

    .name_head,
    .name_data {
        width: 331px;
    }

    .email_head,
    .email_data {
        width: 250px;
    }

    .role_head,
    .role_data {
        width: 120px;
    }

    .action_head,
    .action_data {
        width: 300px;
    }

    .empty {
        flex-grow: 1;
    }
    .loader {
        width: 260px;
    }
    .search {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: stretch;
        padding: 0 10px;
    }
    
    .search_title {
        margin-right: 0;
        font-size: 14px;
    }
    .search_input {
        flex-grow: 0;
        align-self: stretch;
    }
}
