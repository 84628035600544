@import "var.scss";

.CardExpanded {

}

.card {
    // border: 1px solid #eeeeee;
    // border-radius: 4px;
    margin-bottom: 20px;
    transition: border-color 0.3s;
}

.header {
    display: flex;
    align-items: stretch;
    transition: border-color 0.3s;
}
.type {
    flex-grow: 1;
    padding: 8px 0px;
}
.info {
    color: #c4c4c4;
    padding: 8px 24px;
    white-space: nowrap;
}

.status {
    color: $accent;
    padding: 8px 0 8px 24px;
    border-left: 4px solid $accent;
    transition: border-color 0.3s;
    white-space: nowrap;
}

.main {
    padding: 20px 0px;
    position: relative;
}

.archive_btn {
    position: absolute;
    right: 0;
    top: 20px;

    display: flex;
    align-items: center;

    color: $accent;
    cursor: pointer;

    img {
        margin-right: 20px;
    }

    &:hover {
        opacity: 0.7;
    }
}

.creator {
    padding-bottom: 16px;
    width: max-content;
}

.age {
    margin-left: 24px;
}

.fullname {
    color: $text;
    font-weight: bold;
}

.problem {
    margin: 0 45px 24px 0;
}

.attachments_label {
    display: inline-block;
    margin-bottom: 16px;
}

.doctor {
    a {
        color: inherit;
    }
}

.footer {
    padding: 28px 104px 20px 0px;
}

.conclusion {
    padding: 28px 0px;
    // border-bottom: 1px solid #eeeeee;

    &__title {
        display: inline-block;
        margin-bottom: 20px;
    }

    &__button {
        margin-top: 20px;
        max-width: 384px;
    }

    &__file {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    &__text {
        white-space: pre-wrap;
    }
}

.chat {
    margin-top: 8px;
    color: $accent;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
        margin-right: 16px;
    }
}

.buttons {
    margin-top: 10px;
    max-width: 377px;
    margin-bottom: 25px;
    & > * {
        margin-bottom: 15px;
    }
}

.arrow {
    margin-left: 16px;
}

.pickStatusSection {
    padding: 14px 0px;
}
.select {
    margin-bottom: 8px;
}
// радио кнопка выбора специалиста
.SpecialistControl {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 22px;
    input {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
        pointer-events: none;
    }
}
.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.label {
    margin-bottom: 10px;
}
.radio {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    min-width: 30px;
    height: 30px;
    border: 1px solid #d3d5d7;
    border-radius: 50%;
    margin-right: 14px;
    box-shadow: inset 0 0 0 -1px $accent;
    transition: all 0.3s;
    cursor: pointer;
    &.active {
        color: #fff;
        border-color: $accent;
        box-shadow: inset 0 0 0 25px $accent;
    }
}
.subtitle {
    margin-right: 40px;
}
.assignSpecialistBtn {
    margin-top: 35px;
    max-width: 377px;
}

.disabled_link {
    pointer-events: none;
    cursor: default;
}

.price {
    padding: 20px 0px;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}

@include phone {
    .CardExpanded {
      
    }

    .card {
        margin-bottom: 15px;
    }

    .header {
        padding: 10px 15px;
        font-size: 16px;
        line-height: 20px;
        align-items: flex-start;
        flex-direction: column;
    }
    .type {
        padding: 0;
        margin-bottom: 3px;
    }

    .info {
        padding: 0;
        font-size: 13px;
        line-height: 13px;
        margin-bottom: 7px;
    }

    .status {
        padding: 0;
        border-left: none;
    }

    .main {
        padding: 10px 15px;
        font-size: 16px;
        line-height: 24px;
    }

    .creator {
        padding-bottom: 10px;
    }

    .problem {
        margin: 0 27px 15px 0;
    }

    .attachments_label {
        margin-bottom: 10px;
    }

    .doctor {
        margin-bottom: 20px;
    }

    .footer {
        font-size: 16px;
        line-height: 24px;
        justify-content: flex-start;
        flex-direction: column;
        padding: 15px 15px;
    }

    .chat {
        img {
            margin-right: 10px;
        }
    }

    .arrow {
        margin-left: 10px;
    }

    .conclusion {
        padding: 20px 15px 15px;
        margin: 0 auto;

        &__button {
            max-width: 600px;
            margin: 15px auto 0;
            width: 100%;
            padding: 0 27px;
        }

        &__file {
            margin-bottom: 15px;
        }

        &__text {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .buttons {
        margin: 0 auto;
        padding: 15px 15px 0;
        width: 100%;
        max-width: 400px;
    }

    .archive_btn {
        position: initial;
        padding: 5px 0;
        margin-bottom: 10px;

        img {
            margin-right: 10px;
        }
    }

    .pickStatusSection {
        padding: 14px 15px;
        font-size: 16px;
        line-height: 24px;
    }
    // радио кнопка выбора специалиста
    .SpecialistControl {
        flex-direction: column;
        align-items: flex-start;
    }
    .row {
        margin-bottom: 10px;
    }

    .label {
        margin-right: 0px;
        margin-bottom: 10px;
    }
    .subtitle {
        margin-right: 0px;
    }
    .select {
        margin-bottom: 8px;
        max-width: 350px;
    }
    .assignSpecialistBtn {
        margin-top: 20px;
        max-width: 350px;
    }
    .price {
        max-width: 600px;
        margin: 0 auto;
        padding: 15px 27px;
        font-size: 16px;
    }
}
