@import "var.scss";

.EmptySection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    min-height: 500px;
}

.container {
    max-width: 530px;
    width: 100%;
    margin: 0 auto;
}

.title {
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 10px;
}

.text {
    text-align: center;
    line-height: 25px;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 20px;
}

.extra {
    padding: 0 50px;
    display: flex;
    justify-content: center;
}

@include phone {
    .EmptySection {
        min-height: 300px;
    }
    .title {
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 5px;
    }

    .text {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 15px;
    }

    .extra {
        padding: 0 15px;
    }
}
