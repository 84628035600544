@import "var.scss";

.NewPatient {
    flex-grow: 1;
    padding: 20px 40px;
}
.title {
    font-size: 24px;
    line-height: 33px;
    color: $text;
    margin-bottom: 22px;
}
.back {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    & > span {
        margin-left: 14px;
    }
}
.form {
    border: 1px solid #eeeeee;
    border-radius: 4px;
}
.section_title {
    font-weight: normal;
    font-size: 19px;
    line-height: 25px;
    padding: 18px 0 18px 70px;
    border-bottom: 1px solid #eeeeee;
}
.section_content {
    padding: 28px 70px;
    width: 100%;
}
.section {
    padding: 28px 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.inputs {
    width: 100%;
    max-width: 426px;
    input {
        margin-bottom: 20px;
    }
}
.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dataFetchStatus {
    font-size: 12px;
    line-height: 16px;
    .error_status {
        color: $invalid;
    }
    .success_status {
        color: $btn-hover;
    }
}
.checkbox {
    display: block;
    margin-bottom: 20px;

    input {
        display: none;

        &:checked ~ .checkmark {
            background-color: $accent;

            &::after {
                display: block;
                left: 10px;
                top: 6px;
                width: 9px;
                height: 16px;
                border: solid #fff;
                border-width: 0 5px 5px 0;
                transform: rotate(45deg);
            }
        }
    }
}

.checkmark {
    position: relative;
    display: inline-block;
    vertical-align: bottom;
    width: 30px;
    height: 30px;
    border: 1px solid #d3d5d7;
    border-radius: 4px;
    margin-right: 14px;

    &:hover {
        background-color: #f0f1f1;
        cursor: pointer;
    }
    &::after {
        content: "";
        position: absolute;
        display: none;
    }
}

// --------------------- MEDIA

@include phone {
    .NewPatient {
        padding: 25px 0px;
    }
    .title {
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 20px;
        text-align: center;
    }
    .back {
        margin-bottom: 15px;
        margin-left: 27px;
        & > span {
            margin-left: 10px;
        }
    }
    .section_title {
        font-size: 16px;
        line-height: 22px;
        padding: 15px 27px 15px 27px;
    }
    .section_content {
        padding: 20px 27px;
        width: 100%;
        max-width: 450px;
        margin: 0 auto;
    }
    .section {
        padding: 20px 27px;
        max-width: 450px;
        margin: 0 auto;
    }
    .inputs {
        width: 100%;
        max-width: none;
    }
    .checkbox {
        font-size: 16px;
    }
}
