@import 'var.scss';

.NewPassword {
    padding-top: 40px;

    input {
        margin-bottom: 28px;
    }
}

.inner {
    position: relative;
    padding-top: 100px;
    a {
        position: absolute;
        left: -72px;
        top: auto;
    }
}

.title {
    text-align: center;
    font-size: 24px;
    line-height: 33px;

    margin-bottom: 33px;
}

.text {
    text-align: center;
    margin-bottom: 28px;
    line-height: 28px;
}

.errorMessage, .successMessage {
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;
}
.errorMessage {
      color: $error;
}
  
.successMessage {
      color: $accent;
}

@include phone {

    .NewPassword {
        padding-top: 0px;
    
        input {
            margin-bottom: 16px;
        }
    }
    
    .inner {
        padding-top: 60px;
        a {
            left: 0px;
        }
    }
    
    .title {
        font-size: 20px;
        line-height: 27px;
    
        max-width: 200px;
        margin: 0 auto 15px;
    }
    
    .text {
        text-align: center;
        margin-bottom: 20px;
        line-height: 25px;
        font-size: 16px;
    }
    
    .errorMessage, .successMessage {
        margin-top: 5px;
    }   
}