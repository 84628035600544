@import "var.scss";

.Summary {
  flex-grow: 1;
  padding: 20px 40px;
}
.tabs {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 0;
  margin: 0px 0 20px;

  list-style: none;

  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  .count {
    position: absolute;
    top: -5px;
    right: -10px;

    min-width: 20px;
    min-height: 20px;
    padding: 2px 4px;

    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    color: #fff;

    // transform: translateX(50%);
    background: $accent;
    border-radius: 100px;
  }
}
.tab {
  position: relative;

  padding: 10px 10px 20px;
  margin: 10px 10px 0;

  cursor: pointer;
  white-space: nowrap;

  &:hover {
    color: #18d0c3;
  }

  &:before {
    content: "";

    position: absolute;
    bottom: -1px;
    left: -10px;
    width: calc(100% + 20px);
    height: 3px;

    background: transparent;
    border-radius: 100px;
    transition: all 0.3s;
  }

  &.active:before {
    background: #18d0c3;
  }
}
.status_loader {
  align-self: center;
  width: 100%;
  height: 28px;
  margin-top: 15px;
}
.list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.list_item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  padding: 30px 0px;
}
// .list_item:first-child {
//   padding-top: 30px;
// }
.list_item + .list_item {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.icon_wrapper {
  flex: 0 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-right: 15px;

  border-radius: 50%;
  background-color: $accent;
}
.icon_wrapper__mobile {
  display: none;
}
.icon {
  display: inline-block;
  width: 24px;
  height: 24px;

  mask-repeat: no-repeat;
  mask-size: contain;
  background-color: #fff;
  transition: all 0.3s;
}
.info {
  flex: 1 1 auto;
  margin-right: 15px;

  cursor: default;
}
.info_body {
  margin-bottom: 10px;

  .user {
    color: $accent;
    cursor: default;

    &.link {
      cursor: pointer;
    }
  }

  .status {
    color: #bf0000;
  }
}
.date_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0 30px;
}
.date {
  display: inline-block;
  color: #c4c4c4;
}
.button {
  flex: 0 1 250px;
  & > * {
    display: block;
    margin-bottom: 10px;
  }
}

@include phone {
  .Summary {
    padding: 15px 15px;
  }
  .tabs {
    margin-top: -5px;
    padding-top: 5px;
    margin-bottom: 0;

    font-size: 16px;
    overflow-x: scroll;
    overflow-y: visible;

    &::-webkit-scrollbar {
      display: none;
    }
    &::after {
      display: inline;
      content: "";
      padding: 0 7px;
    }
  }
  .tab {
    padding: 10px;
    margin-top: 0;

    &:hover {
      color: inherit;
    }
  }
  .list_item {
    flex-wrap: wrap;
    padding: 20px 0;

    font-size: 16px;
  }
  .button {
    margin-top: 20px;
    flex: 1 1 100%;
  }
  .icon_wrapper__desktop {
    display: none;
  }
  .icon_wrapper__mobile {
    display: flex;
  }
  .info_body {
    display: flex;
  }
}
