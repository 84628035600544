@import "var.scss";

.Covid {
  padding-bottom: 20px;
  color: #000000;
  background: linear-gradient(180deg, #fefefe 43.23%, #f9f9f9 100%);
}
.container {
  flex-grow: 1;
  max-width: 1115px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.subtitle {
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
}
.text {
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  color: #4f4f4f;
}
.hero {
  margin: 60px 0;
  text-align: center;

  &_subtitle {
    margin-bottom: 15px;
  }
  &_text {
    max-width: 816px;
    margin: 0 auto;
  }
}
.factors {
  margin-bottom: 140px;
  &_subtitle {
    max-width: 816px;
    margin: 0 auto 60px;
  }
  &_body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    justify-content: center;
  }
  &_list {
    flex: 0 1 534px;
    margin: 0 92px 20px 0px;
    padding: 0;

    list-style: none;
  }
  &_item {
    position: relative;
    padding-left: 62px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;

      width: 32px;
      height: 32px;

      background-image: url("/images/covid-item-factors.svg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  &_item + &_item {
    margin-top: 40px;
  }
  &_img {
    margin-top: -40px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.causes {
  &_subtitle {
    max-width: 534px;
    margin: 0 auto 60px;
  }
  &_list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 60px;
    padding: 0;
  }
  &_item {
    display: flex;
    align-items: center;
    flex: 0 1 255px;
  }
  &_item__number {
    position: relative;
    height: 94px;
    font-size: 34px;
    line-height: 24px;
    color: $accent;
    span {
      position: absolute;
      top: 36px;
      left: 36px;
      font-size: 34px;
      line-height: 24px;
    }
    img {
      height: 100%;
    }
  }
  &_item__text {
    transform: translateX(-6px);
    flex: 0 0 178px;
    font-size: 16px;
    line-height: 22px;
  }
  &_text {
    max-width: 816px;
    margin: 0 auto 80px;

    text-align: center;
  }
  &_text__icon {
    position: relative;
    z-index: 2;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;

      width: 233px;
      height: 233px;

      background-image: url(/images/virus.svg);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      transform: translate(50%, -50%);
    }
  }
}
.questions {
  margin-bottom: 140px;
  &_subtitle {
    max-width: 535px;
    margin: 0 auto 60px;
  }
  &_body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    justify-content: center;
  }
  &_img {
    margin-top: -50px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &_list {
    flex: 0 1 534px;
    margin: 0;
    margin-left: 38px;
    padding: 0;
    list-style: none;
  }
  &_item {
    position: relative;
    padding-left: 44px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;

      width: 24px;
      height: 24px;

      background-image: url("/images/covid-item-questions.svg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  &_item + &_item {
    margin-top: 25px;
  }
}
.test {
  &_subtitle {
    max-width: 535px;
    margin: 0 auto 15px;
  }
  &_links {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    margin: 0 0 60px;
    padding: 0;

    list-style: none;
  }
  &_link {
    position: relative;
    flex: 0 1 344px;
    align-content: center;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 28px;

    font-size: 14px;
    line-height: 19px;
    text-align: center;

    background: #ffffff;
    border: 2px solid #f5f5f5;
    border-radius: 4px;

    a {
      color: #000000;
    }
  }
  &_link + &_link {
    margin-left: 40px;
  }
  &_link:hover .test_link__bg {
    opacity: 1;
    visibility: visible;
  }
  &_link__bg {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    &::before {
      content: "Пройти тест";
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      width: 100%;

      font-size: 24px;
      line-height: 24px;
      color: #ffffff;
      text-align: center;

      transform: translate(-50%, -50%);
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background: linear-gradient(
        339.2deg,
        #18d0c3 49.68%,
        rgba(24, 208, 195, 0.35) 152.44%
      );
      border-radius: 4px;
    }
  }
}
.explanation {
  margin-bottom: 60px;

  color: $accent;
  text-align: center;
}
.icon {
  width: 45px;
  height: 45px;
  margin: 0 auto 15px;
}
.note,
.author,
.copy {
  margin-bottom: 15px;

  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #4f4f4f;
}
.author {
  max-width: 564px;
  margin: 0 auto 15px;
}
.contacts .subtitle {
  margin-bottom: 30px;
}
.wrapper {
  input {
    margin-bottom: 20px;
  }
}
@include phone {
  .Covid {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
  }
  .subtitle {
    font-size: 16px;
    line-height: 22px;
  }
  .text {
    font-size: 14px;
    line-height: 140%;
  }
  .hero {
    margin: 40px auto 60px;
    &_title {
      font-size: 16px;
      line-height: 19px;
    }
  }
  .factors {
    margin-bottom: 50px;
    &_subtitle {
      margin-bottom: 30px;
    }
    &_list {
      margin: 0;
      margin-bottom: 20px;
    }
    &_item {
      padding-left: 44px;
      font-size: 14px;
      line-height: 140%;
      &::before {
        width: 24px;
        height: 24px;
      }
    }
    &_item + &_item {
      margin-top: 25px;
    }
    &_img {
      margin: 0;
      width: 295px;
      height: 296px;
    }
  }
  .causes {
    &_subtitle {
      margin-bottom: 30px;
    }
    &_list {
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      margin: 0 auto 30px;
      max-width: 250px;
    }
    &_item {
      flex: 0 0 auto;
      max-width: 100%;
    }
    &_item:nth-child(even) {
      flex-direction: row-reverse;
      .causes_item__number {
        img {
          transform: scale(-1, 1);
        }
        span {
          left: 25px;
        }
      }
      .causes_item__text {
        text-align: right;
      }
    }
    &_item + &_item {
      margin-top: 20px;
    }
    &_text {
      margin-bottom: 60px;

      font-size: 14px;
      line-height: 140%;
    }
    &_text__icon::after {
      top: 50px;
    }
  }
  .questions {
    margin-bottom: 50px;
    font-size: 14px;
    line-height: 140%;
    &_subtitle {
      margin-bottom: 30px;
    }
    &_list {
      margin: 0 0 20px;
    }
    &_item {
      padding-left: 32px;
      &::before {
        width: 16px;
        height: 16px;
      }
    }
    &_img {
      order: 1;
      margin: 0;
      width: 295px;
      height: 296px;
    }
  }
  .test {
    &_subtitle {
      margin-bottom: 10px;
    }
    &_links {
      flex-direction: column;
      margin-bottom: 30px;
      align-items: center;
    }
    &_link {
      margin: 0;
      flex: 1 0 174px;
      max-width: 296px;
    }
    &_link + &_link {
      margin-top: 15px;
      margin-left: 0;
    }
  }
  .explanation {
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 140%;
  }
  .icon {
    margin-bottom: 20px;
  }
  .note,
  .author,
  .copy {
    font-size: 12px;
  }
  .author {
    margin-bottom: 10px;
  }
  .copy {
    margin-bottom: 30px;
  }
}
