@import "var.scss";

.RequestItem {
    border: 1px solid #eeeeee;
    border-radius: 4px;
}

.header {
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
}

.title {
    margin-right: auto;
    padding: 14px 24px;
}

.date {
    padding: 14px 26px;
    color: #c4c4c4;
}

.status {
    width: 188px;
    text-align: center;
    padding: 14px;
    color: #c4c4c4;
    border-left: 1px solid #eeeeee;
}

.body {
    padding: 16px 24px 20px;
}

.user {
    margin-bottom: 14px;
    width: fit-content;

}

.name {
    margin-right: 16px;
    font-weight: bold;
}

.buttons {
    display: flex;
    button {
        width: 230px;
        margin-right: 43px;
    }
}

.reject_btn {
    padding: 7px 0;

    color: $accent;
    cursor: pointer;

    &:hover {
        color: $btn-hover;
    }
}

.filler {
    height: 38px;
}

.declined {
    opacity: 0.5;
}

@include phone {
    .RequestItem {
        font-size: 16px;
        line-height: 24px;
    }

    .header {
        align-items: flex-start;
        flex-direction: column;
    }

    .title {
        margin: 0;
        padding: 10px 0 0 10px;
    }

    .date {
        font-size: 13px;
        line-height: 13px;
        padding: 0;
        padding-left: 10px;
        margin-bottom: 5px;
    }

    .status {
        width: auto;
        text-align: left;
        padding: 0 0 10px 10px;
        color: #c4c4c4;
        border-left: none;
    }

    .body {
        padding: 10px;
    }

    .user {
        margin-bottom: 15px;
    }

    .name {
        margin-right: 10px;
    }

    .buttons {
        flex-direction: column;
        justify-content: stretch;
        button {
            width: 100%;
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    .reject_btn {
        text-align: center;
        padding: 7px 20px;

        font-weight: bold;
    }

    .filler {
        height: 10px;
    }
}
