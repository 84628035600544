@import "var.scss";
.payment {
  padding: 28px 0px;
  &__saveBtn {
    margin-top: 28px;
    max-width: 384px;
  }
  &__timeprice_wrapper {
    display: flex;
    flex-direction: row;
  }
  &__time,
  &__price {
    max-width: 182px;
  }
  &__time {
    margin-right: 20px;
  }
}
.checkbox {
  display: block;
  margin-bottom: 16px;

  input {
    display: none;

    &:checked ~ .checkmark {
      background-color: $accent;

      &::after {
        display: block;
        left: 10px;
        top: 6px;
        width: 9px;
        height: 16px;
        border: solid #fff;
        border-width: 0 5px 5px 0;
        transform: rotate(45deg);
      }
    }
  }
}

.checkmark {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  width: 30px;
  height: 30px;
  border: 1px solid #d3d5d7;
  border-radius: 4px;
  margin-right: 14px;

  &:hover {
    background-color: #f0f1f1;
    cursor: pointer;
  }
  &::after {
    content: "";
    position: absolute;
    display: none;
  }
}

@include phone {
  .payment {
    padding: 15px 27px;
    max-width: 600px;
    margin: 0 auto;
    &__saveBtn {
      margin-top: 20px;
      max-width: none;
    }
    &__time {
      margin-right: 10px;
    }
  }
  .checkbox {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 32px;
  }
}
