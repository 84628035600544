@import "var.scss";

.DoctorEdit {
  flex-grow: 1;
  padding: 20px 40px;
}
.title {
  font-size: 24px;
  line-height: 33px;
  color: $text;
  margin-bottom: 22px;
}
.back {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  & > span {
    margin-left: 14px;
  }
}
.form {
  border: 1px solid #eeeeee;
  border-radius: 4px;
}
.picture_loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 200px;
  height: 200px;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  border: 1px dashed #d3d5d7;
  border-radius: 4px;
}

.picture_input {
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.add_btn {
  display: flex;
  flex-direction: column;
  .plus {
    height: 19px;
  }
  &__text {
    margin-top: 12px;
  }
}
.remove_btn {
  position: absolute;
  z-index: 10;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #3f3f3f;
  border-radius: 4px;
  cursor: pointer;
}
.section_title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: normal;
  font-size: 19px;
  line-height: 25px;
  padding: 18px 0 18px 70px;
  border-bottom: 1px solid #eeeeee;
  h5 {
    flex: 1 1 auto;
    font-weight: normal;
    font-size: 19px;
    line-height: 25px;
    display: inline-block;
  }
}
.block_btn {
  float: right;
  margin-right: 22px;
  font-size: 18px;
  line-height: 25px;
  color: #ff4949;
  cursor: pointer;
}
.section_content {
  padding: 28px 70px;
  width: 100%;
}
.section {
  padding: 28px 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.section_withPhoto {
  padding: 28px 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.inputs {
  width: 100%;
  max-width: 426px;
  &_fullWidth {
    max-width: none;
    input,
    select {
      margin-bottom: 20px;
    }
  }
  input {
    margin-bottom: 20px;
  }
}
.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  &_fullWidth {
    flex-wrap: nowrap;
  }
  &__input {
    width: 50%;
    display: flex;
    // justify-content: flex-end;
    &:not(:last-child) {
      padding-right: 20px;
    }
  }
}

.section_withPhoto {
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}

.columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.textarea {
  width: 100%;
  height: 220px;
  border: 1px solid #d3d5d7;
  border-radius: 4px;
  padding: 12px 23px;
  margin-top: 31px;
  margin-bottom: 28px;
  resize: none;
  overflow-y: scroll;

  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;

  &::placeholder {
    color: #c4c4c4;
  }

  &:focus {
    border-color: $accent;
    outline: none;

    & + label {
      color: $accent;
    }
  }

  &:hover {
    border-color: $accent;
  }

  &.textarea_invalid {
    border-color: $invalid;
    margin-top: 0px;
  }
}

.errorMessage {
  color: $invalid;
  font-size: 12px;
}

.dataFetchStatus {
  font-size: 12px;
  line-height: 16px;
  .error_status {
    color: $invalid;
  }
  .success_status {
    color: $btn-hover;
  }
}
.questionarie {
  &__instruction {
    margin-bottom: 25px;
  }
}

.furtherEducation {
  &_row {
    flex-direction: column;
    & > * {
      width: 100%;
      justify-content: flex-start;
    }
  }
  &__fileInput {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 110px;
    .fileInput__title {
      font-size: 14px;
      line-height: 24px;
      margin-right: 15px;
      align-self: baseline;
    }
    .loader {
      margin-top: 5px;
    }
  }
}
.scienceCount {
  &__title {
    margin-bottom: 16px;
  }
  &__row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 20px;
    &__input {
      margin-right: 14px;
      max-width: 100px;
      input {
        margin-bottom: 0;
      }
    }
  }
}
.consultationCount {
  &__title {
    margin-bottom: 16px;
  }
  &__row {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    &__input {
      margin-right: 14px;
      max-width: 200px;
      input {
        margin-bottom: 0;
      }
    }
  }
}
.checkbox {
  display: block;
  margin-bottom: 16px;

  input {
    display: none;

    &:checked ~ .checkmark {
      background-color: $accent;

      &::after {
        display: block;
        left: 10px;
        top: 6px;
        width: 9px;
        height: 16px;
        border: solid #fff;
        border-width: 0 5px 5px 0;
        transform: rotate(45deg);
      }
    }
  }
}

.checkmark {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  width: 30px;
  height: 30px;
  border: 1px solid #d3d5d7;
  border-radius: 4px;
  margin-right: 14px;

  &:hover {
    background-color: #f0f1f1;
    cursor: pointer;
  }
  &::after {
    content: "";
    position: absolute;
    display: none;
  }
}
.methods {
  &__row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  &__title {
    width: 70%;
    padding-right: 20px;
  }
  &__price {
    width: 30%;
  }
}
.table {
  &__thRow {
    text-align: left;
    padding-right: 20px;
    width: 50%;
  }
  &__tdRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    margin-right: 20px;
  }
  &__trRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
  }
}
.time {
  &__pretext {
    width: 20px;
    margin-right: 10px;
  }
}

// ----------------- MEDIA

@include phone {
  .back {
    margin-left: 27px;
  }
  .DoctorEdit {
    padding: 25px 0px;
  }
  .title {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 20px;
    margin-left: 27px;
  }

  .picture_loader {
    align-self: center;
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
  }

  .add_btn {
    .plus {
      height: 12px;
    }
    &__text {
      font-size: 14px;
      margin-top: 5px;
    }
  }

  .remove_btn {
    top: 0px;
    right: 0px;
    width: 30px;
    height: 30px;
  }
  .section_title {
    flex-direction: column-reverse;
    font-size: 16px;
    line-height: 22px;
    padding: 15px 27px 15px 27px;
  }
  .block_btn {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .section_content {
    padding: 25px 27px 20px;
    width: 100%;
  }
  .section {
    padding: 20px 27px;
  }
  .inputs {
    margin: 0 auto;
  }
  .section_withPhoto {
    padding: 20px 27px;
    flex-direction: column-reverse;
  }
  .questionarie__instruction {
    font-size: 14px;
    line-height: 22px;
  }
  .row {
    &_fullWidth {
      flex-wrap: wrap;
    }
    &__input {
      width: 100%;
      display: flex;
      justify-content: stretch;
      &:not(:last-child) {
        padding: 0;
        margin: 0;
      }
    }
  }
  .columns {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 15px;
  }
  .furtherEducation_row {
    flex-direction: column;
  }
  .furtherEducation {
    &__fileInput {
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      .fileInput__title {
        font-size: 13px;
        line-height: 16px;
        padding-top: 0px;
        margin-right: 0;
        margin-bottom: 5px;
      }
      .loader {
        left: 0;
        top: 62px;
      }
    }
  }
  .work_experience_row {
    flex-wrap: wrap;
    margin-bottom: 10px;
    .row__input:nth-child(1),
    .row__input:nth-child(2) {
      width: 100%;
    }
  }
  .scienceCount {
    &__title {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 10px;
    }
    &__row {
      margin-bottom: 20px;
      &__input {
        margin-right: 10px;
        max-width: 50px;
      }
      &__inputText {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  .table {
    font-size: 16px;
    width: 100%;
    &__tdRow {
      justify-content: center;
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
    &__trRow {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      padding-bottom: 20px;
    }
  }
  .checkbox {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .checkmark {
    margin-right: 8px;
  }
  .consultationCount__row {
    font-size: 16px;
    line-height: 22px;
    &__input {
      max-width: 100px;
    }
  }

  .methods {
    &__row {
      flex-direction: column;
      margin-bottom: 10px;
    }
    &__title {
      width: 100%;
      padding-right: 0;
    }
    &__price {
      width: 100px;
    }
  }
  .textarea {
    font-size: 14px;
    line-height: 22px;
    height: 180px;
    padding: 10px;
    margin-top: 0px;
    margin-bottom: 15px;
  }
}
