@import "var.scss";

.ArticleEdit {
  flex-grow: 1;
  padding: 17px 48px 48px 40px;
}

.back {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  & > span {
    margin-left: 14px;
  }
}
.picture_loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 375px;
  margin: 0 auto;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  border: 1px dashed #d3d5d7;
  border-radius: 4px;

  margin-bottom: 34px;
  margin-top: 26px;
}

.picture_input {
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}

.remove_btn {
  position: absolute;
  z-index: 10;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #3f3f3f;
  border-radius: 4px;
  cursor: pointer;
}
.title {
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 42px;
  font-weight: normal;
}

.form {
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding: 48px 60px;
}

.input_wrapper {
  margin-bottom: 20px;
}

.btn_submit{
    max-width: 426px;
    margin-top: 28px;
}
.dataFetchStatus {
  font-size: 12px;
  line-height: 16px;
  .error_status {
    color: $invalid;
  }
  .success_status {
    color: $btn-hover;
  }
}

@include phone {
  .ArticleEdit {
    padding: 25px 0 30px;
  }

  .back {
    margin-bottom: 20px;
    margin-left: 27px;
    & > span {
      margin-left: 10px;
    }
  }

  .title {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 20px;
  }

  .form {
    border: 1px solid #eeeeee;
    border-radius: 4px;
    padding: 20px 27px 15px;
  }
  .picture_loader {
    width: 100%;
    height: 150px;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .remove_btn {
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
  }

  .btn_submit {
    max-width: none;
    margin-top: 10px;
  }
  
  .input_wrapper {
    margin-bottom: 15px;
  }
}