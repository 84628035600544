@import "var.scss";

.NewInvite {
  flex-grow: 1;
  padding: 17px 48px 48px 40px;
}

.back {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  & > span {
    margin-left: 14px;
  }
}

.title {
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 42px;
  font-weight: normal;
}

.form {
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding: 48px 60px;
  input {
    margin-bottom: 25px;
  }
}

.btn_submit{
    max-width: 426px;
    margin-top: 28px;
}
.dataFetchStatus {
  font-size: 12px;
  line-height: 16px;
  .error_status {
    color: $invalid;
  }
  .success_status {
    color: $btn-hover;
  }
}

@include phone {
  .NewInvite {
    padding: 25px 0 30px;
  }

  .back {
    margin-bottom: 20px;
    margin-left: 27px;
    & > span {
      margin-left: 10px;
    }
  }

  .title {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 20px;
  }

  .form {
    border: 1px solid #eeeeee;
    border-radius: 4px;
    padding: 20px 27px 15px;
    input {
      margin-bottom: 10px;
    }
  }
  .btn_submit {
    max-width: none;
  }
}
