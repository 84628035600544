@import "var.scss";

.MyPatients {
    flex-grow: 1;
    padding: 16px 44px;
    display: flex;
    flex-direction: column;
}

.title {
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 20px;
}

.search_wrapper {
    margin-bottom: 25px;
}

.table_wrapper {
    margin-bottom: auto;
}

.loader {
    margin: 50px auto 0;
    width: 300px;
}


@include phone {
    .MyPatients {
        padding: 25px 5px;
    }

    .title {
        margin-left: 5px;
        margin-bottom: 20px;
    }

    .table_wrapper {
        overflow-x: scroll;
    }
    .loader {
        width: 260px;
    }
}