@import 'var.scss';

.TextArea {
    resize: none;
    width: 100%;
    height: 148px;
    border: 1px solid #d3d5d7;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 12px 23px;
    font-size: 18px;
    line-height: 25px;
    font-family: 'Open sans', sans-serif;
    &::placeholder {
        color: #c4c4c4;
    }

    &:focus {
        border-color: $accent;
        outline: none;

        & + .label {
            color: $accent;
        }
    }

    &:hover {
        border-color: $accent;
        & + .label {
            color: $accent;
        }
    }
}

.wrapper {
    position: relative;
    width: 100%;
}

.label {
    position: absolute;
    left: 18px;
    top: -10px;
    background-color: #ffffff;
    padding: 0 5px;
    font-size: 14px;
    line-height: 19px;

    color: #d3d5d7;
}

.invalid {
    &.TextArea {
        border: 1px solid $invalid;
        & + .label {
            color: $invalid;
        }
    }
}

.error_message {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: $invalid;
  }

@include phone {
    .TextArea {
        padding: 10px 10px;
        font-size: 16px;
        line-height: 22px;
    }
    
    .label {
        font-size: 13px;
    }
}