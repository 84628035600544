@import "var.scss";

.ConsultationsLogTable {
  border-collapse: collapse;
  font-size: 16px;

  max-width: 100%;

  th {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $accent;
    text-align: left;
    padding: 10px 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  td {
    padding: 10px 5px;
  }
}

.served_head {
  width: 170px;
}

.performer_head {
  width: 250px;
}

.date_head {
  width: 150px;
}

@include phone {
  .ConsultationsLogTable {
    max-width: 700px;
    min-width: 500px;
    margin: 0 auto;
    font-size: 11px;
    line-height: 16px;

    th {
      font-size: 11px;
      line-height: 16px;
    }
  }
  .served_head {
    min-width: 130px;
  }

  .performer_head {
    min-width: 200px;
  }

  .date_head {
    min-width: 120px;
  }
}
