@import "var.scss";

.DoctorItem {
    display: flex;
    align-items: center;
    padding: 11px 11px 11px 39px;

    border: 1px solid #eeeeee;
    border-radius: 4px;
    color: inherit;
    cursor: pointer;

    transition: border-color 0.3s;
    &:hover,
    &.active {
        border-color: $accent;
    }
}

.info {
    flex-grow: 1;
}

.name {
    margin-bottom: 6px;
}

.photo {
    width: 84px;
    height: 84px;
    border-radius: 4px;
    min-width: 84px;
    margin-left: 10px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #eeeeee;
}

@include phone {
    .DoctorItem {
        padding: 10px;
        min-width: 260px;
        width: fit-content;
    }

    .info {
        font-size: 14px;
        line-height: 19px;
    }

    .name {
        margin-bottom: 3px;
    }

    .photo {
        width: 50px;
        min-width: 50px;
        height: 50px;
    }
}
