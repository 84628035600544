@import "var.scss";

.Accesses {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
}

.title_active {
    font-size: 24px;
    line-height: 33px;
    color: #181818;
}

.title {
    font-size: 24px;
    line-height: 33px;
    color: #181818;
    opacity: 0.5;
    cursor: pointer;
    margin-right: 45px;
}

.titleWrapper {
    display: flex;
    margin-bottom: 20px;
}

.tabs {
    margin-bottom: 43px;
}

.list {
    margin-bottom: auto;
    & > * {
        margin-bottom: 20px;
    }
}

.tip {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
}

@include phone {
    .Accesses {
        padding: 25px 15px 0;
    }

    .title_active {
        font-size: 20px;
        line-height: 27px;
    }

    .title {
        font-size: 20px;
        line-height: 27px;
        margin-right: 0;
        margin-bottom: 5px;
    }
    .titleWrapper {
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 15px;
    }
    .tip {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 15px;
    }

    .tabs {
        padding: 0 15px;
        margin: 0 -15px 20px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .list {
        margin-bottom: auto;
        & > * {
            margin-bottom: 15px;
        }
    }
}
