@import "var.scss";

.Chat {
  flex-grow: 1;
}
.header {
  height: 55px;
  background: rgba(238, 238, 238, 0.2);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 25px;
  margin-bottom: 18px;
  border-left: 4px solid $accent;
  &__title {
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #181818;
  }
}
.messages {
  scroll-behavior: smooth;
  height: 480px;
  padding-bottom: 25px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.message {
  margin-bottom: 30px;
  &__column {
    border-left: 5px solid #eeeeee;
    padding-left: 5px;
  }
  &__role {
    font-size: 14px;
    line-height: 19px;
    color: #c4c4c4;
    margin-bottom: 3px;
  }
  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__name {
    font-weight: bold;
    margin-right: 8px;
    font-size: 16px;
    line-height: 22px;
    color: #181818;
  }
  &__time {
    font-size: 16px;
    line-height: 22px;
    color: #c4c4c4;
  }
  &__text {
    padding-left: 10px;
    margin-top: 10px;
    font-size: 16px;
    line-height: 22px;
    color: #181818;
    word-break: break-word;
  }
  &__files {
    margin-top: 10px;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.ref {
  padding: 10px 0;
}

.bottom {
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1070px;
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
  padding-top: 10px;
  &__fileInput {
    width: 48px;
    height: 48px;
    .button {
      width: 48px;
      height: 48px;
      border: 1px solid #18d0c3;
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.disabled {
        cursor: not-allowed;
      }
      &:hover {
        border: 1px solid #12a59a;
      }
    }
    .icon {
      width: 24px;
      height: 24px;
      background: url("/profile/images/clip.svg");
    }
    .input {
      width: 0px;
      height: 0px;
      visibility: hidden;
    }
  }
  &__textInput {
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
  }
  &__sendBtn {
    height: 48px;
    width: 100%;
    max-width: 100px;
    background-color: $accent;
    border-radius: 4px;
  }
  &__attachedFiles {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    max-width: 1070px;
  }
}
// кнопка доп загрузки сообщений
.loadPrevMessages {
  align-self: center;
  border: none;
  outline: none;
  font-size: 18px;
  line-height: 25px;
  color: #181818;
  background: transparent;
  cursor: pointer;
  &:hover {
    color: #18d0c3;
  }
}

@include phone {
  .Chat {
    padding: 0 15px;
  }
  .message {
    &__files {
      & > * {
        margin-bottom: 5px;
        margin-right: 10px;
      }
    }
  }
  .bottom {
    .row {
      flex-direction: column;
      align-items: flex-end;
      max-width: 600px;
      width: 100%;
    }
    padding: 20spx 15px;
    &__fileInput {
      width: 40px;
      height: 40px;
      margin-bottom: 15px;
      .button {
        width: 40px;
        height: 40px;
      }
      .icon {
        width: 24px;
        height: 24px;
        background: url("/profile/images/clip.svg");
      }
    }
    &__textInput {
      margin: 0 0 15px;
      width: 100%;
      max-width: 765px;
    }
    &__sendBtn {
      width: 100%;
    }
    &__attachedFiles {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
      & > * {
        margin-bottom: 5px;
      }
    }
  }
}
