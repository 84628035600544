@import "var.scss";

.Articles {
    min-height: 697px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px 25px; 
    padding: 0 40px 5px;
    max-width: 1440px;
    margin: 0 auto;
}

.title {
    margin: 40px 0;
    text-align: center;
    font-size: 24px;
    line-height: 33px;
    font-weight: normal;
}


@include phone {
    .Articles {
        min-height: 400px;
        display: block;
        min-width: 0;
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        padding: 0;
        & > * {
            margin: 15px;
        }
    }
    .title {
        margin: 0;
        padding: 25px 0 15px;
        font-size: 20px;
        line-height: 27px;
    }
}
