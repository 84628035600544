@import "var.scss";

.Health {
    flex-grow: 1;
    padding: 16px 50px 50px 40px;
}

.tests {
    display: flex;
    flex-direction: column;
    padding-bottom: 48px;
}

.title {
    margin-bottom: 35px;
    font-size: 24px;
    line-height: 33px;
}

.test_list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
}

.more {
    align-self: flex-end;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;

    img {
        margin-left: 10px;
    }
}

@include phone {

    .Health {
        padding: 25px 15px 40px 15px;
    }
    
    .tests {
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;
    }
    
    .title {
        text-align: center;
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 27px;
    }

    .test_list {
        margin: 0;
        flex-direction: column;
    }
    
    .more {
        align-self: center;
        img {
            margin-left: 10px;
        }
    }
    
}
