@import "var.scss";

.TestItem {
    display: flex;
    flex-direction: column;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    margin-bottom: 20px;
    margin-right: 20px;
    min-width: 300px;
    flex: 1 0 300px;
}

.header {
    display: flex;
    border-bottom: 1px solid #eeeeee;
}

.about {
    font-size: 16px;
    flex-grow: 1;
    padding: 6px 6px 5px 16px;
}

.questions {
    font-size: 13px;
    line-height: 13px;
    color: #c4c4c4;
}

.type {
    min-width: 123px;
    padding: 14px 9px;
    border-left: 1px solid #eeeeee;
    color: #c4c4c4;
    text-align: center;
}

.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 16px 20px;
}

.description {
    margin-bottom: 20px;
    flex-grow: 1;
}

.btn {
    width: 90px;
}

@include phone {
    .TestItem {
        margin: 0;
        margin-bottom: 15px;
        min-width: auto;
        flex: auto;
    }

    .about {
        padding: 6px 6px 5px 10px;
    }

    .type {
        font-size: 16px;
        min-width: 100px;
    }

    .main {
        font-size: 16px;
        line-height: 24px;
        padding: 10px 10px 15px;
    }

    .description {
        margin-bottom: 15px;
        flex-grow: 1;
    }

    .btn {
        width: 90px;
    }
}
