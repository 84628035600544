@import "var.scss";

.TipModal {
    position: fixed;
    z-index: 100;
    bottom: 0;
    right: 0;
    background-color: $sidebar;
    color: #ffffff;
    padding: 17px 55px 17px 20px;
    border-top-left-radius: 5px;
    max-width: 443px;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    animation-name: slideinout;
    animation-duration: .5s;
    img {
        position: absolute;
        top: 28px;
        right: 20px;
        padding: 5px;
        cursor: pointer;
        width: 22px;
        height: 22px;
    }
}

@keyframes slideinout {
    0% {
        bottom: -100%;
    }
    100% {
        bottom: 0;
    }
}
