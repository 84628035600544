@import "var.scss";

.InviteTable {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;

    th {
        text-transform: uppercase;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: $accent;
        text-align: left;
        padding: 10px 5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    td {
        padding: 10px 5px;
    }
}

.email_head {
    width: 500px;
}

.send_head {
    width: 400px;
}

.registered_head {
    width: 400px;
}

.name_head {
    width: 740px;
}

@include phone {
    .InviteTable {
        max-width: 700px;
        min-width: 500px;
        margin: 0 auto;
        font-size: 11px;
        line-height: 16px;

        th {
            font-size: 11px;
            line-height: 16px;
        }
    }
}
