@import "var.scss";

.table_wrapper {
    margin-bottom: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
    line-height: 25px;
    th {
        text-transform: uppercase;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: $accent;
        text-align: left;
        padding: 10px 5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    td {
        padding: 10px 5px;
    }
}

.time_head,
.time_data {
    width: 188px;
    white-space: nowrap;
}

.name_head,
.name_data {
    width: 331px;
}

.email_head,
.email_data {
    width: 250px;
}

.role_head,
.role_data {
    width: 143px;
}

.action_head,
.action_data {
    width: 393px;
}

@include phone {

    .table_wrapper {
        overflow-x: scroll;
        margin: 0 15px;
        margin-bottom: auto;
    }

    .table {
        min-width: 400px;
        font-size: 11px;
        line-height: 16px;
        th {
            font-size: 11px;
            line-height: 16px;
        }
    }  

    .name_head,
    .name_data {
        width: 331px;
    }

    .email_head,
    .email_data {
        width: 250px;
    }

    .role_head,
    .role_data {
        width: 120px;
    }

    .action_head,
    .action_data {
        width: 300px;
    }
}