@import "var.scss";

.ConfirmationMail {
    padding-top: 40px;
}

.inner {
    padding-top: 86px;
}

.title {
    text-align: center;
    font-size: 24px;
    line-height: 33px;

    margin-bottom: 60px;
}

.icon {
    display: block;
    margin: 0 auto 22px;
}

.subtitle {
    text-align: center;
    font-size: 24px;
    line-height: 33px;
    margin: 6px 0 28px;
}

.text {
    text-align: center;
    margin-bottom: 28px;
}

.buttons {
    button {
        margin-bottom: 20px;
    }
}

@include phone {
    .ConfirmationMail {
        padding-top: 0px;
    }

    .inner {
        padding-top: 60px;
    }

    .title {
        font-size: 20px;
        line-height: 27px;

        margin-bottom: 30px;
    }

    .icon {
        display: block;
        margin: 0 auto 22px;
    }

    .subtitle {
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 20px;
    }

    .text {
        text-align: center;
        margin-bottom: 20px;
        font-size: 16px;
    }

}
