@import "var.scss";

.TimePicker {
  color: $text;
  border: 1px solid #d3d5d7;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 11px 64px 11px 23px;
  font-size: 18px;
  line-height: 25px;
  outline: none;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url("/profile/images/select-btn.svg") calc(100% - 13px) 50%
      no-repeat,
    url("/profile/images/line.svg") calc(100% - 40px) 50% repeat-y;

  option {
    color: #000;
  }

  &:disabled {
    opacity: 0.4;
    background-color: #fff;
  }

  &::-ms-expand {
    display: none;
  }
  &:hover {
    border-color: $accent;
  }
}
