@import "var.scss";

.Sidebar {
    position: sticky;
    top: 60px;
    z-index: 50;
    min-width: 280px;
    background-color: $sidebar;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 60px);
}

.nav_list {
    padding: 0;
    margin: 0;
}

.nav_item {
    transition: box-shadow 0.3s;
    color: #ffffff;
}

.nav_item:hover,
.nav_item a.active {
    background-color: $bg;
    box-shadow: inset 8px 0 0 0 $accent;
    color: #000000;
    cursor: pointer;
}

.nav_item a {
    outline: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 17px 20px 18px 40px;

    font-weight: 400;
    line-height: 25px;
    text-decoration: none;

    cursor: pointer;

    color: inherit;
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 20px;

    mask-repeat: no-repeat;
    mask-size: contain;
    background-color: #fff;
    transition: all 0.3s;
}

.nav_item:hover .icon,
.nav_item a.active .icon {
    background-color: #000;
}

.btn {
    padding: 25px;
    & > * {
        display: block;
        margin-bottom: 10px;
    }
}

.toggle {
    display: flex;
    align-items: center;
    color: #ffffff;
    margin-top: auto;
    padding: 18px 0 18px 40px;
    cursor: pointer;

    img {
        margin-right: 22px;
    }

    &:hover {
        opacity: 0.5;
    }
}

.logout {
    display: none;
    margin: auto 15px 80px;
    a {
        color: #fff;
    }
}

.collapsed {
    min-width: 108px;
    font-size: 0;

    .toggle {
        transform: rotate(180deg);
    }
}

@include phone {
    .Sidebar {
        background-color: $accent;
        max-width: 0;
        min-width: 0;
        width: 0;
        position: fixed;
        height: 100%;
        overflow: hidden;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .btn {
        padding: 25px 15px;
    }

    .nav_item:hover,
    .nav_item a.active {
        box-shadow: inset 8px 0 0 0 $sidebar;
    }

    .nav_item a {
        padding-left: 15px;
    }

    .toggle {
        display: none;
    }
    .logout {
        margin-top: 30px;

        display: block;
    }
}
