@import "var.scss";

.DoctorCard {
    font-size: 16px;
    line-height: 24px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 90px;
    max-width: 203px;
    padding: 20px;
    color: inherit;
    background: #ffffff;
    border: 2px solid #f5f5f5;
    border-radius: 4px;

    &:hover {
        border: 2px solid $accent;
    }
}

.DoctorCard + .DoctorCard {
    margin-left: 20px;
}

@include phone {
    .DoctorCard {
        flex: 0 0 193px;
    }  
}
