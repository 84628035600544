@import "var.scss";

.HealthStatusIcon {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    border: 1px solid #ffffff;
    transition: all 0.2s ease-in-out;
}

.wrapper {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

@include phone {
    .HealthStatusIcon {
        width: 20px;
        height: 20px;
    }
}
