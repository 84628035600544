@import "var.scss";

.Tabs {
    display: flex;
    justify-content: stretch;
    margin: 0 0;
}

.item {
    font-size: 16px;
    line-height: 24px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 90px;
    max-width: 203px;
    min-width: 110px;
    padding: 20px;
    margin: 0 15px;

    color: inherit;
    background: #ffffff;
    border: 2px solid #f5f5f5;
    border-radius: 4px;

    &:hover {
        border: 2px solid $accent;
    }
}

.active {
    border: 2px solid $accent;
}

@include phone {
    .Tabs {
        font-size: 16px;
        line-height: 22px;
        margin: 0 -20px;

        // Для отступа справа
        &::after {
            display: block;
            content: "";
            padding: 0 1px;
        }
    }

    .item {
        min-width: 203px;
    }
}
